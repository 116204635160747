import React, { Fragment } from "react";
import EmailNavs from "../layouts/EmailNavs";

const ShellContainer = () => {
	return (
		<Fragment>
			<EmailNavs />
		</Fragment>
	);
};

export default ShellContainer;
