import React, { Fragment } from "react";
import EmailTable from "../layouts/EmailTable";

const EmailContainer = () => {
	return (
		<Fragment>
			<EmailTable />
		</Fragment>
	);
};

export default EmailContainer;
