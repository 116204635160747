import _ from "underscore";

const optionRestrictions = {
	"Vendor" : [
        "No",
        "Unbilled"
	],
	"Vendor Copy" : [
        "No",
        "Unbilled"
	],
	"PO" : [
        "No",
        "Unbilled",
        "% Complete"
	],
	"PO Copy" : [
        "No",
        "Unbilled",
        "% Complete"
	]
};

const methods = {};

methods.getKeyValueList = (record, options) => {
	let keyList = [];

	Object.values(options).forEach((o) => {
		if (o.key !== undefined) {
			if (methods.filterOptions(record, o)) {
				keyList.push({ id: o.key, value: o.value });	
			}
		}
	});
	return keyList;
};

methods.filterOptions = (record, option) => {
	if (record && option.value) {
		let allowed = optionRestrictions[record.task_type];
		if (allowed && !allowed.includes(option.value)) {
			return false;
		}
	}
	return true;
};

methods.getValueFromKey = (key, options) => {
	let found = _.find(Object.values(options), (o) => {
		if (o.key === key) {
			return true;
		}
		return false;
	});
	return found ? found.value : "";
};


export default Object.freeze({ ...methods });
