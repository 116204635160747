export const envNameKey = (envName) => {
	if (envName === "aws_qa") {
		return "qa";
	} else if (envName === "aws_staging") {
		return "staging";
	} else if (envName === "aws_uat") {
		return "uat";
	} else if (envName === "aws_prod") {
		return "prod";
	} else if (envName === "aws_login") {
		return "login";
	} else {
		return envName;
	}
};
