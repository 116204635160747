import { ActionTypes } from "../constants/action-types";

const initalState = {
	emails: [],
};

export const emailReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_EMAILS:
			return { ...state, emails: payload };

		default:
			return state;
	}
};
