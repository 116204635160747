import { ActionTypes } from "../constants/action-types";

const initalState = {
	schedules: [],
};

export const taskSchedulerReducer = (
	state = initalState,
	{ type, payload }
) => {
	switch (type) {
		case ActionTypes.SET_TASKS_SCHEDULES:
			return { ...state, schedules: payload };

		default:
			return state;
	}
};
