import React from "react";
import { TimePicker } from "antd";

const DatePickerForm = ({
	label,
	placeholder,
	format,
	use12Hours,
	onChange,
	showNow,
}) => {
	return (
		<div className="selector-container">
			<div className="input-lbl">{label}</div>
			<TimePicker
				placeholder={placeholder}
				format={format}
				use12Hours={use12Hours}
				onChange={onChange}
				showNow={showNow}
			/>
		</div>
	);
};

export default DatePickerForm;
