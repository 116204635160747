import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { SendStatusEnum, SentTypeEnum } from "../utils/enums";
import { getEmails, getCustomerId } from "../services/baseAPI";
import { setEmails } from "../context/actions/emailAction";
import { setUser } from "../context/actions/userAction";
import DynamicTable from "../components/DynamicTable";
import moment from "moment";

const EmailTable = () => {
	const emails = useSelector((state) => state.allEmails.emails);
	const user = useSelector((state) => state.allUsers.user);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");
	const companyId = searchParams.get("companyId");
	const emailSenderId = searchParams.get("emailSenderId");
	const [size, setSize] = useState(25);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const header = [
		{
			title: "Date Sent",
			dataIndex: "createdDate",
			key: "createdDate",
			render: (item) => <div>{moment(item).format("LLL")}</div>,
			sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
		},
		{
			title: "Sent To",
			dataIndex: "toEmails",
			key: "toEmails",
			sorter: (a, b) => a.toEmails.localeCompare(b.toEmails),
		},
		{
			title: "CC To",
			dataIndex: "toCcEmails",
			key: "toCcEmails",
			sorter: (a, b) =>
				a.toCcEmails &&
				b.toCcEmails &&
				a.toCcEmails.localeCompare(b.toCcEmails),
		},
		{
			title: "Status",
			dataIndex: "sendStatus",
			key: "sendStatus",
			render: (item) => (
				<div
					className={"table-tag"}
					style={{
						color: SendStatusEnum.getColorFromKey(item),
						background: SendStatusEnum.getBgColorFromKey(item),
					}}
				>
					{item}
				</div>
			),
			sorter: (a, b) =>
				SendStatusEnum.getDscpFromKey(a.sendStatus).localeCompare(
					SendStatusEnum.getDscpFromKey(b.sendStatus)
				),
		},
		{
			title: "Email Type",
			dataIndex: "emailType",
			key: "emailType",
			render: (item) => (
				<div
					className={"table-tag"}
					style={{
						color: SentTypeEnum.getColorFromKey(item),
						background: SentTypeEnum.getBgColorFromKey(item),
					}}
				>
					{SentTypeEnum.getDscpFromKey(item) === ""
						? item
						: SentTypeEnum.getDscpFromKey(item)}
				</div>
			),
			sorter: (a, b) =>
				a.emailType && a.emailType && a.emailType.localeCompare(b.emailType),
		},

		{
			title: "Request Id",
			dataIndex: "requestId",
			key: "requestId",
			sorter: (a, b) => a.requestId - b.requestId,
		},
	];

	const fetchEmails = async (
		customerId,
		emailSenderId,
		endDate,
		startDate,
		size,
		page
	) => {
		const response = await getEmails(
			customerId,
			emailSenderId,
			endDate,
			startDate,
			size,
			page
		).catch((err) => {
			console.log(err);
		});
		console.log("response", response);
		if (response) {
			setIsLoading(false);
			dispatch(setEmails(response.data));
		}
	};

	const getUserInfo = async (companyId, token, emailSenderId) => {
		const response = await getCustomerId(companyId, token, emailSenderId).catch(
			(err) => {
				console.log(err);
			}
		);

		console.log(response);
		if (response) {
			dispatch(setUser(response.data));
			fetchEmails(response.data.companyId, emailSenderId);
		}
	};

	const onChangeStartDate = (date) => {
		setStartDate(moment(date).format("l"));
	};

	const onChangeEndDate = (date) => {
		setEndDate(moment(date).format("l"));
	};

	const onClickFilter = async () => {
		setIsLoading(true);
		fetchEmails(user.companyId, emailSenderId, endDate, startDate, size);
	};

	const onChangeTable = async (pagination, filters, sorter) => {
		let pageSize = pagination.pageSize;
		let curPage = pagination.current;

		setIsLoading(true);
		setSize(pageSize);
		fetchEmails(
			user.companyId,
			emailSenderId,
			endDate,
			startDate,
			pageSize,
			curPage
		);
	};

	useEffect(() => {
		getUserInfo(companyId, token, emailSenderId); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div style={{ padding: "20px" }}>
				<Row className="table-input">
					<Col md={{ span: 2, offset: 7 }}>
						<DatePicker placeholder="Start Date" onChange={onChangeStartDate} />
					</Col>
					<Col md={2}>
						<DatePicker placeholder="End Date" onChange={onChangeEndDate} />
					</Col>
					<Col md={1}>
						<Button type="primary" onClick={onClickFilter}>
							Filter
						</Button>
					</Col>
				</Row>

				<DynamicTable
					data={emails.content}
					columnHeader={header}
					loading={isLoading}
					pagination={{
						pageSize: emails.size,
						total: emails.totalElements,
					}}
					onChange={onChangeTable}
				/>
			</div>
		</Fragment>
	);
};
export default EmailTable;
