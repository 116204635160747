import React from "react";
import { InputNumber } from "antd";
import "antd/dist/antd.css";

const InputNumberField = ({
	label,
	value,
	placeholder,
	onChange,
	min,
	max,
	defaultValue,
}) => (
	<div className="input-container">
		{label && <div className="input-lbl">{label}</div>}
		<InputNumber
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			min={min}
			max={max}
			defaultValue={defaultValue}
		/>
	</div>
);

export default InputNumberField;
