import axios from "axios";

let headers = {
	Origin: window.location.href,
	"Access-Control-Allow-Origin": "*",
};

const axiosIstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers,
});

const axiosInstanceTaskManager = axios.create({
	baseURL: process.env.REACT_APP_TASK_MANAGER,
	withCredentials: true,
});

const axiosInstanceWeb = axios.create({
	baseURL: process.env.REACT_APP_WEB,
	headers,
});

const axiosInstanceTaskScheduler = axios.create({
	baseURL: process.env.REACT_APP_TASK_SCHEDULER,
	headers,
});

export {
	axiosIstance,
	axiosInstanceTaskManager,
	axiosInstanceWeb,
	axiosInstanceTaskScheduler,
};
