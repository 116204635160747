import React from "react";
import { Checkbox } from "antd";
import "antd/dist/antd.css";

const CheckboxForm = ({
	label,
	disabled,
	onChange,
	defaultChecked,
	checked,
}) => (
	<div className="input-container">
		<div className="input-lbl">{label}</div>
		<Checkbox
			checked={checked}
			disabled={disabled}
			defaultChecked={defaultChecked}
			onChange={onChange}
		/>
	</div>
);

export default CheckboxForm;
