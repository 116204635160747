import React from "react";
import { useDispatch } from "react-redux";
import {
	setTaskParams,
	setTasks,
	setTotalTasks,
	setIsLoading,
	setCurrPage,
} from "../context/actions/taskManagerAction";
import {
	CalculationTypeEnum,
	VendorTaskTypeEnum,
} from "../utils/enums";
import { getVendorTasks } from "../services/baseAPI";
import { Input, Select, Form } from "antd";

const { Option } = Select;

/*eslint eqeqeq: "off"*/
const TaskManagerFilter = ({columns, externalOptions, internalOptions}) => {
	
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	/*
	* ENG-1249 - Update Naming Convention for Accrual Cloud
	* @params columns - Object containing all column headers
	* @params api_name - api_name of the column header to match to columns object
	* @returns String of the label of the column header from columns Object that matches api_name
	*/
	const getLabel = (headers, api_name) => {
		
		var label;

		headers.forEach((key) => {
			if (key.api_name === api_name) {
				label = key.label;
			}
		});
		
		return label ? label : null;
	}

	const onform = async (values, allValues) => {
		//change empty string to undefined
		Object.keys(allValues).forEach((key) => {
			if (allValues[key] == "") {
				allValues[key] = undefined;
			}
		});

		dispatch(setTaskParams(allValues));
		dispatch(setIsLoading(true));

		const response = await getVendorTasks(allValues);
		if (response && response.data) {
			dispatch(setTasks(response.data));
			dispatch(setTotalTasks(response.headers["x-total-count"]));
			dispatch(setIsLoading(false));
			dispatch(setCurrPage(1));
		} else {
			dispatch(setTasks([]));
			dispatch(setIsLoading(false));
			dispatch(setCurrPage(0));
		}
	};

	return (
		<Form form={form} onValuesChange={onform} className="filter-wrapper">
			<Form.Item name="reviewed">
				<Select placeholder={getLabel(columns,'reviewed') ? getLabel(columns,'reviewed') : "Reviewed"}>
					<Option key={"0"} value={"No"}>
						No
					</Option>
					<Option key={"1"} value={"Yes"}>
						Yes
					</Option>
					<Option key={"All"} value={"All"}>
						All
					</Option>
				</Select>
			</Form.Item>
			<Form.Item name="name">
				<Input placeholder={getLabel(columns,'name') ? getLabel(columns,'name') : "Vendor Name"} />
			</Form.Item>
			<Form.Item name="task_inactive">
				<Select placeholder={getLabel(columns,'task_inactive') ? getLabel(columns,'task_inactive') : "Task Inactive"}>
					<Option key={"No"} value={"No"}>
						No
					</Option>
					<Option key={"Yes"} value={"Yes"}>
						Yes
					</Option>
					<Option key={"All"} value={"All"}>
						All
					</Option>
				</Select>
			</Form.Item>
			<Form.Item name="external_accrual">
				{/* ENG-1249 - Update Naming Convention for Accrual Cloud */}
				<Select placeholder={getLabel(columns, "external_accrual") ? getLabel(columns,'external_accrual') : "External Accrual"}>
					{externalOptions && externalOptions.map((item) => (
						<Option key={item.id} value={item.value}>
							{item.value}
						</Option>
					))}
					<Option key={"All"} value={"All"}>
						All
					</Option>
				</Select>
			</Form.Item>
			<Form.Item name="internal_accrual">
				<Select placeholder={getLabel(columns,'internal_accrual') ? getLabel(columns,'internal_accrual') : "Internal Accrual"}>
					{internalOptions && internalOptions.map((item) => (
						<Option key={item.id} value={item.value}>
							{item.value}
						</Option>
					))}
					<Option key={"All"} value={"All"}>
						All
					</Option>
				</Select>
			</Form.Item>
			<Form.Item name="calculation">
				<Select placeholder={getLabel(columns,'calculation') ? getLabel(columns,'calculation') : "Calculation"}>
					{CalculationTypeEnum.getKeyValueList().map((item) => (
						<Option key={item.id} value={item.value}>
							{item.value}
						</Option>
					))}
					<Option key={"All"} value={"All"}>
						All
					</Option>
				</Select>
			</Form.Item>
			<Form.Item name="subsidiary">
				<Input placeholder={getLabel(columns,'subsidiary') ? getLabel(columns,'subsidiary') : "Subsidiary"} />
			</Form.Item>
			<Form.Item name="glaccount_debit">
				<Input placeholder={getLabel(columns,'glaccount_debit') ? getLabel(columns,'glaccount_debit') : "GL Account - Debit"} />
			</Form.Item>
			<Form.Item name="glaccount_credit">
				<Input placeholder={getLabel(columns,'glaccount_credit') ? getLabel(columns,'glaccount_credit') : "GL Account - Credit"} />
			</Form.Item>

			<Form.Item name="department_debit">
				<Input placeholder={getLabel(columns,'department_debit') ? getLabel(columns,'department_debit') : "Department - Debit"} />
			</Form.Item>
			<Form.Item name="department_credit">
				<Input placeholder={getLabel(columns,'department_credit') ? getLabel(columns,'department_credit') : "Department - Credit"} />
			</Form.Item>
			<Form.Item name="task_type">
				<Select placeholder={getLabel(columns,'task_type') ? getLabel(columns,'task_type') : "Task Type"} mode="multiple">
					{VendorTaskTypeEnum.getKeyValueList().map((item) => (
						<Option key={item.id} value={item.value}>
							{item.value}
						</Option>
					))}
				</Select>
			</Form.Item>
		</Form>
	);
};

export default TaskManagerFilter;
