import _ from "underscore";

class AccrualsEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}

const toReturn = {
	NO: new AccrualsEnum(1, "No", "#00a3ed", "#ddf4ff"),
	YES: new AccrualsEnum(2, "Unbilled", "#e6bf00", "#fbf6e1"),
	// ENG-114: Percentage of Completion - Task Manager
	POC: new AccrualsEnum(3, "Percentage of Completion", "#7f39b2", "#f0e4f9"),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

methods.getKeyValueList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

methods.getKeyFromDscp = (dscp) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.dscp === dscp) {
			return true;
		}

		return false;
	});
	return found ? found.key : "";
};

export default Object.freeze({ ...toReturn, ...methods });
