import React, { Fragment } from "react";
import TaskMangerTable from "../layouts/TaskManagerTable";

const TaskMangerContainer = () => {
	return (
		<Fragment>
			<TaskMangerTable />
		</Fragment>
	);
};

export default TaskMangerContainer;
