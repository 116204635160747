import moment from "moment";

export const convertMinutesToDaysAndTime = (days, hours, minutes) => {
	return `${days} day/s at ${moment(`${hours}:${minutes}`, ["HH.mm"]).format(
		"hh:mm A"
	)} `;
};

//(24*60) - (converTime.hour(0 * 60 + converTime.minute(0)

export const convertDaysAndTimeToMinutes = (days, time) => {
	let convertDaysMinutes = days * 24 * 60;
	let convertTime = moment(time);
	let convertMinutes = 0;

	if (convertDaysMinutes < 0) {
		convertMinutes =
			24 * 60 - ((23 - convertTime.hour()) * 60 + (60 - convertTime.minute()));
	} else {
		convertMinutes = convertTime.hour() * 60 + convertTime.minute();
	}

	let totalConvertedMins = 0;

	if (convertDaysMinutes < 0) {
		totalConvertedMins = convertDaysMinutes - convertMinutes;
	} else {
		totalConvertedMins = convertDaysMinutes + convertMinutes;
	}

	return totalConvertedMins;
};
