import { ActionTypes } from "../constants/action-types";

const initalState = {
	user: {},
};

export const userReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_USER:
			return { ...state, user: payload };

		default:
			return state;
	}
};
