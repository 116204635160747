import Menu from "../components/Menu";
import TopHeader from "../components/TopHeader";

const EmailNavs = () => {
	return (
		<body
			ng-app="app"
			id="app"
			data-custom-background=""
			data-off-canvas-nav=""
		>
			<div ng-controller="AppCtrl">
				<TopHeader />
				<Menu />
			</div>
		</body>
	);
};

export default EmailNavs;
