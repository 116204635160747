import React, { Fragment, useState } from "react";
import { Button, Form, message, Tooltip, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import { createTaskSchedule } from "../services/baseAPI";
import {
	TaskTypeEnum,
	DaysTypeEnum,
	ScheduleTypeEnum,
	WorkTypeEnum,
} from "../utils/enums";
import Selector from "../components/Selector";
import CheckboxForm from "../components/CheckBoxForm";
import TimePicker from "../components/TimePicker";
import InputNumberField from "../components/InputNumberField";
import { convertDaysAndTimeToMinutes } from "../utils/dateUtils";

const AddScheduleForm = ({ companyId, list, envName, companyName }) => {
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleCancel = (e) => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const onClickAdd = () => {
		setIsModalVisible(true);
	};

	const onFinish = (values) => {
		let isExisting = false;
		let totalConvertedMins = convertDaysAndTimeToMinutes(
			values.runOffsetFullDays,
			values.runOffsetTime
		);

		const { runOffsetFullDays, runOffsetTime, ...newValues } = values;
		let data = {
			...newValues,
			customerName: companyName,
			customerId: companyId,
			runOffsetMinutes: totalConvertedMins,
			active: values.active === undefined ? false : values.active,
			envName: envName,
		};

		// Checking of duplicate task type and if all schedule
		list.forEach((item) => {
			if (item.taskType === data.taskType) {
				isExisting = true;
			}
		});

		if (!isExisting) {
			createTaskSchedule(data, envName).then((res) => {
				if (res.status === 200) {
					message.success("Record was successfully added.");
				} else {
					message.error("Error in adding the record.");
				}
				setIsModalVisible(false);
				form.resetFields();
				window.location.reload(false);
			});
		} else {
			message.error(`Schedule with ${data.taskType} task type already exist.`);
		}
	};

	return (
		<Fragment>
			<div className="add-button no-pagination">
				<Tooltip title="Add Schedule">
					<Button type="primary" size="large" onClick={onClickAdd}>
						<div className="btn-icon-lbl">
							<PlusOutlined />
						</div>
					</Button>
				</Tooltip>
				<Modal
					title="Add Schedule"
					visible={isModalVisible}
					width={800}
					footer={null}
					onCancel={handleCancel}
				>
					<Form form={form} onFinish={onFinish}>
						<Row>
							<Col md={12} sm={12}>
								<Form.Item
									name="taskType"
									rules={[
										{ required: true, message: "This field is required." },
									]}
								>
									<Selector
										label="Task Type"
										showSearch
										valueKey="id"
										nameKey="value"
										options={TaskTypeEnum.getKeyValueListNoManual()}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col md={12} sm={12}>
								<Form.Item
									name="daysType"
									rules={[
										{ required: true, message: "This field is required." },
									]}
								>
									<Selector
										label="Days Type"
										showSearch
										valueKey="id"
										nameKey="value"
										options={DaysTypeEnum.getKeyValueList()}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col md={6} sm={12}>
								<Form.Item
									name="workType"
									rules={[
										{ required: true, message: "This field is required." },
									]}
								>
									<Selector
										label="Work Type"
										showSearch
										valueKey="id"
										nameKey="value"
										options={WorkTypeEnum.getKeyValueList()}
									/>
								</Form.Item>
							</Col>
							<Col md={6} sm={12}>
								<Form.Item
									name="scheduleType"
									rules={[
										{ required: true, message: "This field is required." },
									]}
								>
									<Selector
										label="Schedule Type"
										showSearch
										valueKey="id"
										nameKey="value"
										options={ScheduleTypeEnum.getKeyValueList()}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col md={6} sm={12}>
								<Form.Item
									name="runOffsetFullDays"
									rules={[
										{ required: true, message: "This field is required." },
									]}
								>
									<InputNumberField
										label="Run Days Offset"
										max={31}
										min={-31}
									/>
								</Form.Item>
							</Col>
							<Col md={4} sm={12}>
								<Form.Item
									name="runOffsetTime"
									rules={[
										{
											type: "object",
										},
										{ required: true, message: "This field is required." },
									]}
								>
									<TimePicker
										label="Run Time Offset"
										use12Hours
										format="h:mm a"
										showNow={false}
									/>
								</Form.Item>
							</Col>
							<Col md={2} sm={12}>
								<Form.Item name="active" valuePropName="checked">
									<CheckboxForm defaultChecked={false} label="Active" />
								</Form.Item>
							</Col>
						</Row>

						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form>
				</Modal>
			</div>
		</Fragment>
	);
};

export default AddScheduleForm;
