import _ from "underscore";

class JobRunStatusEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}

const toReturn = {
	NEW: new JobRunStatusEnum("New", "New", "#e6bf00", "#fbf6e1"),
	DONE: new JobRunStatusEnum("Done", "Done", "#00a3ed", "#ddf4ff"),
	RUNNING: new JobRunStatusEnum("Running", "Running", "#7f39b2", "#f0e4f9"),
	RETRY: new JobRunStatusEnum("Retry", "Retry", "#51c8c4", "#e2fffe"),
	ERROR: new JobRunStatusEnum("Error", "Error", "#ed0000", "#ffdddd"),
	SKIP: new JobRunStatusEnum("Skip", "Skip", "#e6bf00", "#fbf6e1"),
	FAILED: new JobRunStatusEnum("Failed", "Failed", "#7f39b2", "#f0e4f9"),
	RECURRING: new JobRunStatusEnum(
		"Recurring",
		"Recurring",
		"#51c8c4",
		"#e2fffe"
	),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

export default Object.freeze({ ...toReturn, ...methods });
