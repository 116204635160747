import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DyanmicTable from "../components/DynamicTable";
import {
	getVendorTasks,
	updateVendorTasks,
	updateVendorDetailsTasks,
	deleteVendorTasks,
	getSourceCoaOptions,
	getSourceVendorOptions,
	getFormsOptions,
	getAuthVendorTask,
	copyVendorTasks,
	massUpdateVendorTasks,
	massUpdateVendorTasksDetails,
	getTaskManagerDynamicColumn,
} from "../services/baseAPI";
import moment from "moment";
import {
	Checkbox,
	Input,
	Popconfirm,
	Select,
	message,
	DatePicker,
	Radio,
	Button,
} from "antd";
import {
	CopyOutlined,
	DeleteOutlined,
	HistoryOutlined,
} from "@ant-design/icons";
import {
	setTasks,
	setTotalTasks,
	setIsLoading,
	setCurrPage,
} from "../context/actions/taskManagerAction";

import { CalculationTypeEnum, VendorTaskTypeEnum } from "../utils/enums";

import OptionsRestriction from "../utils/OptionsRestriction";

import TableTag from "../components/TableTag";
import TaskManagerFilter from "./TaskManagerFilter";
const { Option } = Select;

/*eslint eqeqeq: "off"*/
const TaskManagerTable = () => {
	const tasks = useSelector((state) => state.allTasks.tasks);
	const totalTasks = useSelector((state) => state.allTasks.totalTasks);
	const taskParams = useSelector((state) => state.allTasks.taskParams);
	const isLoading = useSelector((state) => state.allTasks.isLoading);
	const currPage = useSelector((state) => state.allTasks.currPage);
	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const companyId = searchParams.get("companyId");
	const token = searchParams.get("token");
	const roles = searchParams.entries();
	const [editKey, setEditKey] = useState("");
	const [vendorList, setVendorList] = useState([]);
	const [subsidiaryList, setSubsidiaryList] = useState([]);
	const [deptList, setDeptList] = useState([]);
	const [glAccountList, setGlAccountList] = useState([]);
	const [formList, setFormList] = useState([]);

	const [companyList, setCompanyList] = useState([]);
	const [interCompanyList, setInterCompanyList] = useState([]);
	const [channelList, setChannelList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [productList, setProductList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [externalList, setExternalList] = useState([]);
	const [internalList, setInternalList] = useState([]);

	const [hasEditRole, setHasEditRole] = useState(false);
	const [totalItemPerPage, setTotalItemPerPage] = useState(100);

	const [selectedRows, setSelectedRows] = useState([]);
	const [columnValue, setColumnValue] = useState({
		key: "",
		value: null,
	});
	const [columnVendorName, setColumnVendorName] = useState({
		name: "",
		vendor_id: null,
	});
	const [columnValueVendorTaskDetails, setColumnValueTaskDetails] = useState(
		{}
	);
	const [applyToSelectedRows, setApplyToSelectedRows] = useState([]);
	const [columnHeaders, setColumnHeaders] = useState([]);
	const [showHeader, setShowHeader] = useState(false);

	const hasEditableKeys = [
		"name",
		"email",
		"internal_email",
		"cc_email",
		"internal_cc_email",
		"subsidiary",
		"subsidiary_currency",
		"glaccount_debit",
		"glaccount_credit",
		"department_debit",
		"department_credit",
		"company_debit",
		"company_credit",
		"intercompany_debit",
		"intercompany_credit",
		"channel_debit",
		"channel_credit",
		"location_debit",
		"location_credit",
		"class_debit",
		"class_credit",
		"product_debit",
		"product_credit",
		"project_debit",
		"project_credit",
		"region_debit",
		"region_credit",
		"transaction_currency",
		"po_owner",
		"po_line_status",
		"service_start_date",
		"service_end_date",
		"po_line_amount",
		"invoiced_amount",
		"po_line_amount_cancelled",
		"po_balance",
		"po_number",
		"po_line_description",
		"po_line_id",
		"po_id",
		"po_header_description",
		"po_header_amount",
	];

	const isEditable = (item) => {
		if (item.internal_source_purchase_order_id && !item.parent_id) {
			return false;
		}

		return true;
	};

	const canCopy = (hasRole, parentId) => {
		if (hasRole && !parentId) {
			return true;
		}
		return false;
	};

	const canDelete = (hasRole, parent_id) => {
		if (hasRole && parent_id) {
			return true;
		}
		return false;
	};

	const reviewString = (item) => {
		if (item == "1") {
			return "Yes";
		} else if (item == "0" || item == "" || item == null) {
			return "No";
		}

		return item;
	};

	const getColumnHeaders = () => {
		//ENG-1115 default columns, cannot be removed
		getTaskManagerDynamicColumn().then((res) => {
			if (res && res.data) {
				setColumnHeaders(res.data);
			}
		});
	};

	const grayLabel = (item, isDisabled) => {
		return <div style={{ color: isDisabled && "#abaaaa" }}>{item}</div>;
	};

	const setColumns = () => {
		let columns = [
			{
				title: "Action",
				key: "",
				fixed: "left",
				width: 90,
				render: (item, record) => (
					<span className="table-action">
						<Popconfirm
							placement="bottom"
							title={"Are you sure you want to copy row?"}
							onConfirm={onClickCopy(item, record)}
							okText="Yes"
							cancelText="No"
							disabled={!canCopy(hasEditRole, record.parent_id)}
						>
							<CopyOutlined
								className={
									!canCopy(hasEditRole, record.parent_id) && "disabled-item"
								}
							/>
						</Popconfirm>
						<Popconfirm
							placement="bottom"
							title={"Are you sure you want to delete?"}
							onConfirm={onClickRemove(item)}
							okText="Yes"
							cancelText="No"
							disabled={!canDelete(hasEditRole, record.parent_id)}
						>
							<DeleteOutlined
								className={
									!canDelete(hasEditRole, record.parent_id) && "disabled-item"
								}
							/>
						</Popconfirm>
						<HistoryOutlined
							onClick={onClickHistory(item)}
							className="sample"
						/>
					</span>
				),
			},
			{
				title: () => (
					<Checkbox
						defaultChecked={false}
						checked={selectedRows.length === tasks.length}
						onChange={onChangeCheckboxAll(tasks)}
					/>
				),
				key: "",
				fixed: "left",
				width: 50,
				render: (row) => (
					<span className="table-action">
						<Checkbox
							defaultChecked={false}
							checked={isRowSelected(row)}
							onChange={onChangeSelectCheckbox(row)}
						/>
					</span>
				),
			},
		];

		columnHeaders.map((item) => {
			if (item.api_name == "reviewed") {
				columns.push({
					title: item.label, // ENG-1249 - Change title to dynamic
					dataIndex: "reviewed",
					key: "reviewed",
					fixed: "left",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Radio.Group
												onChange={onChangeSetColumnValue("reviewed")}
												defaultValue={item}
											>
												<Radio.Button value="1">Yes</Radio.Button>
												<Radio.Button value="0">No</Radio.Button>
											</Radio.Group>
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													defaultValue={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"reviewed",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>

											<div className="bulk-buttons">
												<Button size="small" onClick={onClickCancel} ghost>
													Cancel
												</Button>
												<Button
													size="small"
													onClick={onClickApplyChangesToSelectedRows(
														"reviewed",
														record,
														item
													)}
												>
													Apply
												</Button>
											</div>
										</div>
									) : (
										<Checkbox
											defaultChecked={item == "1" ? true : false}
											onChange={onChangeCheckbox("reviewed", record)}
										/>
									)}
								</>
							) : (
								<span>{reviewString(item)}</span>
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "name") {
				columns.push({
					title: item.label, // ENG-1249 - Change title to dynamic
					dataIndex: ["vendor_task_details", "name"],
					key: "vendor_name",
					fixed: "left",
					width: 250,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id &&
							isEditable(record) &&
							!record.internal_source_vendor_id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangeColumnVendorName("name", "vendor_id")}
											>
												{vendorList &&
													vendorList.map((item) => (
														<Option key={item.id} value={item.value}>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"name",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorName(
															"name",
															"vendor_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"name",
												record,
												true,
												"vendor_id"
											)}
											allowClear
											onClear={onClearSelector(
												"name",
												record,
												true,
												"vendor_id"
											)}
										>
											{vendorList && (
												<Fragment>
													<Option />
													{vendorList.map((item) => (
														<Option key={item.id} value={item.value}>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								item &&
								grayLabel(
									item,
									!(isEditable(record) && !record.internal_source_vendor_id)
								)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "task_inactive") {
				columns.push({
					title: item.label,
					dataIndex: item.api_name,
					key: item.api_name,
					width: 180,
					render: (item) => (
						<Fragment>
							<span>
								{grayLabel(
									item == "1" ? "Yes" : item == "0" ? "No" : item,
									true
								)}
							</span>
						</Fragment>
					),
				});
			} else if (item.api_name == "external_accrual") {
				columns.push({
					title: item.label,
					dataIndex: "external_accrual",
					key: "external_accrual",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												defaultValue={OptionsRestriction.getValueFromKey(
													item,
													externalList
												)}
												valueKey="id"
												onChange={onChangePickListSetColumnValue(
													"external_accrual"
												)}
											>
												{OptionsRestriction.getKeyValueList(
													record,
													externalList
												).map((options, index) => (
													<Option key={index} value={options.id}>
														{options.value}
													</Option>
												))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"external_accrual",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"external_accrual",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											defaultValue={OptionsRestriction.getValueFromKey(
												item,
												externalList
											)}
											valueKey="id"
											onSelect={onChangeSelector("external_accrual", record)}
											allowClear
											onClear={onClearSelector("external_accrual", record)}
										>
											<Option />
											{OptionsRestriction.getKeyValueList(
												record,
												externalList
											).map((options, index) => (
												<Option key={index} value={options.id}>
													{options.value}
												</Option>
											))}
										</Select>
									)}
								</>
							) : (
								OptionsRestriction.getValueFromKey(item, externalList)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "internal_accrual") {
				columns.push({
					title: item.label,
					dataIndex: "internal_accrual",
					key: "internal_accrual",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												defaultValue={OptionsRestriction.getValueFromKey(
													item,
													internalList
												)}
												valueKey="id"
												onChange={onChangePickListSetColumnValue(
													"internal_accrual"
												)}
											>
												{OptionsRestriction.getKeyValueList(
													record,
													internalList
												).map((options, index) => (
													<Option key={index} value={options.id}>
														{options.value}
													</Option>
												))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"internal_accrual",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"internal_accrual",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											defaultValue={OptionsRestriction.getValueFromKey(
												item,
												externalList
											)}
											onSelect={onChangeSelector("internal_accrual", record)}
											allowClear
											onClear={onClearSelector("internal_accrual", record)}
										>
											<Option />
											{OptionsRestriction.getKeyValueList(
												record,
												internalList
											).map((options, index) => (
												<Option key={index} value={options.id}>
													{options.value}
												</Option>
											))}
										</Select>
									)}
								</>
							) : (
								OptionsRestriction.getValueFromKey(item, internalList)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "calculation") {
				columns.push({
					title: item.label,
					dataIndex: "calculation",
					key: "calculation",
					width: 200,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												defaultValue={item}
												valueKey="id"
												onChange={onChangePickListSetColumnValue("calculation")}
											>
												{CalculationTypeEnum.getKeyValueList(record).map(
													(options, index) => (
														<Option key={index} value={options.id}>
															{options.value}
														</Option>
													)
												)}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"calculation",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"calculation",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											defaultValue={item}
											onSelect={onChangeSelector("calculation", record)}
											allowClear
											onClear={onClearSelector("calculation", record)}
										>
											<Option />
											{CalculationTypeEnum.getKeyValueList(record).map(
												(options, index) => (
													<Option key={index} value={options.id}>
														{options.value}
													</Option>
												)
											)}
										</Select>
									)}
								</>
							) : (
								CalculationTypeEnum.getDscpFromKey(item)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "email") {
				columns.push({
					title: item.label,
					dataIndex: "email",
					key: "email",
					width: 230,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id &&
							isEditable(record) &&
							!record.internal_source_vendor_id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputField("email")}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"email",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"email",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField("email", record, false)}
										></Input>
									)}
								</>
							) : (
								grayLabel(
									item,
									!(isEditable(record) && !record.internal_source_vendor_id)
								)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "internal_email") {
				columns.push({
					title: item.label,
					dataIndex: "internal_email",
					key: "internal_email",
					width: 230,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputField("internal_email")}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"internal_email",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"internal_email",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"internal_email",
												record,
												false
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "cc_email") {
				columns.push({
					title: item.label,
					dataIndex: "cc_email",
					key: "cc_email",
					width: 230,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id &&
							isEditable(record) &&
							!record.internal_source_vendor_id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputField("cc_email")}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"cc_email",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"cc_email",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField("cc_email", record)}
										></Input>
									)}
								</>
							) : (
								grayLabel(
									item,
									!(isEditable(record) && !record.internal_source_vendor_id)
								)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "internal_cc_email") {
				columns.push({
					title: item.label,
					dataIndex: "internal_cc_email",
					key: "internal_cc_email",
					width: 230,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputField("internal_cc_email")}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"internal_cc_email",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"internal_cc_email",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											// onChange={onChangeField("g_transaction_group", record)}
											onPressEnter={onPressEnterField(
												"internal_cc_email",
												record
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "subsidiary") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "subsidiary"],
					key: "subsidiary",
					width: 250,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"subsidiary",
													"subsidiary_id"
												)}
												optionFilterProp="children"
											>
												{subsidiaryList &&
													subsidiaryList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"subsidiary",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"subsidiary",
															"subsidiary_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"subsidiary",
												record,
												true,
												"subsidiary_id"
											)}
											allowClear
											onClear={onClearSelector(
												"subsidiary",
												record,
												true,
												"subsidiary_id"
											)}
											optionFilterProp="children"
										>
											{subsidiaryList && (
												<Fragment>
													<Option />
													{subsidiaryList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "subsidiary_currency") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "subsidiary_currency"],
					key: "subsidiary_currency",
					width: 180,
					render: (item) => <Fragment>{grayLabel(item, true)}</Fragment>,
				});
			} else if (item.api_name == "glaccount_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "glaccount_debit"],
					key: "glaccount_debit",
					width: 300,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"glaccount_debit",
													"glaccount_debit_id"
												)}
												optionFilterProp="children"
											>
												{glAccountList &&
													glAccountList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"glaccount_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"glaccount_debit",
															"glaccount_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"glaccount_debit",
												record,
												true,
												"glaccount_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"glaccount_debit",
												record,
												true,
												"glaccount_debit_id"
											)}
										>
											{glAccountList && (
												<Fragment>
													<Option />
													{glAccountList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "glaccount_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "glaccount_credit"],
					key: "glaccount_credit",
					width: 300,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"glaccount_credit",
													"glaccount_credit_id"
												)}
												optionFilterProp="children"
											>
												{glAccountList &&
													glAccountList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"glaccount_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"glaccount_credit",
															"glaccount_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"glaccount_credit",
												record,
												true,
												"glaccount_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"glaccount_credit",
												record,
												true,
												"glaccount_credit_id"
											)}
										>
											{glAccountList && (
												<Fragment>
													<Option />
													{glAccountList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "department_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "department_debit"],
					key: "department_debit",
					width: 200,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"department_debit",
													"department_debit_id"
												)}
												optionFilterProp="children"
											>
												{deptList &&
													deptList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"department_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"department_debit",
															"department_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"department_debit",
												record,
												true,
												"department_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"department_debit",
												record,
												true,
												"department_debit_id"
											)}
										>
											{deptList && (
												<Fragment>
													<Option />
													{deptList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "department_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "department_credit"],
					key: "department_credit",
					width: 200,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"department_credit",
													"department_credit_id"
												)}
												optionFilterProp="children"
											>
												{deptList &&
													deptList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"department_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"department_credit",
															"department_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"department_credit",
												record,
												true,
												"department_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"department_credit",
												record,
												true,
												"department_credit_id"
											)}
										>
											{deptList && (
												<Fragment>
													<Option />
													{deptList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "company_debit") {
				columns.push({
					title: "Company - Debit",
					dataIndex: ["vendor_task_details", "company_debit"],
					key: "company_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"company_debit",
													"company_debit_id"
												)}
												optionFilterProp="children"
											>
												{companyList &&
													companyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"company_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"company_debit",
															"company_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"company_debit",
												record,
												true,
												"company_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"company_debit",
												record,
												true,
												"company_debit_id"
											)}
										>
											<Option />
											{companyList && (
												<Fragment>
													{companyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "company_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "company_credit"],
					key: "company_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"company_credit",
													"company_credit_id"
												)}
												optionFilterProp="children"
											>
												{companyList &&
													companyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"company_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"company_credit",
															"company_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"company_credit",
												record,
												true,
												"company_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"company_credit",
												record,
												true,
												"company_credit_id"
											)}
										>
											<Option />
											{companyList && (
												<Fragment>
													{companyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "intercompany_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "intercompany_debit"],
					key: "intercompany_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"intercompany_debit",
													"intercompany_debit_id"
												)}
												optionFilterProp="children"
											>
												{interCompanyList &&
													interCompanyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"intercompany_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"intercompany_debit",
															"intercompany_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"intercompany_debit",
												record,
												true,
												"intercompany_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"intercompany_debit",
												record,
												true,
												"intercompany_debit_id"
											)}
										>
											{interCompanyList && (
												<Fragment>
													<Option />
													{interCompanyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "intercompany_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "intercompany_credit"],
					key: "intercompany_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"intercompany_credit",
													"intercompany_credit_id"
												)}
												optionFilterProp="children"
											>
												{interCompanyList &&
													interCompanyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"intercompany_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"intercompany_credit",
															"intercompany_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"intercompany_credit",
												record,
												true,
												"intercompany_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"intercompany_credit",
												record,
												true,
												"intercompany_credit_id"
											)}
										>
											{interCompanyList && (
												<Fragment>
													<Option />
													{interCompanyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "channel_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "channel_debit"],
					key: "channel_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"channel_debit",
													"channel_debit_id"
												)}
												optionFilterProp="children"
											>
												{channelList &&
													channelList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"channel_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"channel_debit",
															"channel_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"channel_debit",
												record,
												true,
												"channel_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"channel_debit",
												record,
												true,
												"channel_debit_id"
											)}
										>
											{channelList && (
												<Fragment>
													<Option />
													{channelList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "channel_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "channel_credit"],
					key: "channel_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"channel_credit",
													"channel_credit_id"
												)}
												optionFilterProp="children"
											>
												{channelList &&
													channelList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"channel_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"channel_credit",
															"channel_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"channel_credit",
												record,
												true,
												"channel_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"channel_credit",
												record,
												true,
												"channel_credit_id"
											)}
										>
											{channelList && (
												<Fragment>
													<Option />
													{channelList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "location_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "location_debit"],
					key: "location_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"location_debit",
													"location_debit_id"
												)}
												optionFilterProp="children"
											>
												{locationList &&
													locationList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"location_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"location_debit",
															"location_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"location_debit",
												record,
												true,
												"location_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"location_debit",
												record,
												true,
												"location_debit_id"
											)}
										>
											{locationList && (
												<Fragment>
													<Option />
													{locationList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "location_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "location_credit"],
					key: "location_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"location_credit",
													"location_credit_id"
												)}
												optionFilterProp="children"
											>
												{locationList &&
													locationList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"location_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"location_credit",
															"location_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"location_credit",
												record,
												true,
												"location_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"location_credit",
												record,
												true,
												"location_credit_id"
											)}
										>
											{locationList && (
												<Fragment>
													<Option />
													{locationList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "class_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "class_debit"],
					key: "class_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"class_debit",
													"class_debit_id"
												)}
												optionFilterProp="children"
											>
												{classList &&
													classList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"class_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"class_debit",
															"class_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"class_debit",
												record,
												true,
												"class_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"class_debit",
												record,
												true,
												"class_debit_id"
											)}
										>
											{classList && (
												<Fragment>
													<Option />
													{classList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "class_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "class_credit"],
					key: "class_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"class_credit",
													"class_credit_id"
												)}
												optionFilterProp="children"
											>
												{classList &&
													classList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"class_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"class_credit",
															"class_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"class_credit",
												record,
												true,
												"class_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"class_credit",
												record,
												true,
												"class_credit_id"
											)}
										>
											{classList && (
												<Fragment>
													<Option />
													{classList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "product_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "product_debit"],
					key: "product_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"product_debit",
													"product_debit_id"
												)}
												optionFilterProp="children"
											>
												{productList &&
													productList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"product_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"product_debit",
															"product_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"product_debit",
												record,
												true,
												"product_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"product_debit",
												record,
												true,
												"product_debit_id"
											)}
										>
											{productList && (
												<Fragment>
													<Option />
													{productList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "product_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "product_credit"],
					key: "product_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"product_credit",
													"product_credit_id"
												)}
												optionFilterProp="children"
											>
												{productList &&
													productList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"product_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"product_credit",
															"product_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"product_credit",
												record,
												true,
												"product_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"product_credit",
												record,
												true,
												"product_credit_id"
											)}
										>
											{productList && (
												<Fragment>
													<Option />
													{productList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "project_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "project_debit"],
					key: "project_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"project_debit",
													"project_debit_id"
												)}
												optionFilterProp="children"
											>
												{projectList &&
													projectList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"project_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"project_debit",
															"project_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"project_debit",
												record,
												true,
												"project_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"project_debit",
												record,
												true,
												"project_debit_id"
											)}
										>
											{projectList && (
												<Fragment>
													<Option />
													{projectList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "project_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "project_credit"],
					key: "project_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"project_credit",
													"project_credit_id"
												)}
												optionFilterProp="children"
											>
												{projectList &&
													projectList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"project_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"project_credit",
															"project_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"project_credit",
												record,
												true,
												"project_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"project_credit",
												record,
												true,
												"project_credit_id"
											)}
										>
											{projectList && (
												<Fragment>
													<Option />
													{projectList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "region_debit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "region_debit"],
					key: "region_debit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"region_debit",
													"region_debit_id"
												)}
												optionFilterProp="children"
											>
												{regionList &&
													regionList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"region_debit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"region_debit",
															"region_debit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"region_debit",
												record,
												true,
												"region_debit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"region_debit",
												record,
												true,
												"region_debit_id"
											)}
										>
											{regionList && (
												<Fragment>
													<Option />
													{regionList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "region_credit") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "region_credit"],
					key: "region_credit",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"region_credit",
													"region_credit_id"
												)}
												optionFilterProp="children"
											>
												{regionList &&
													regionList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"region_credit",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"region_credit",
															"region_credit_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"region_credit",
												record,
												true,
												"region_credit_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"region_credit",
												record,
												true,
												"region_credit_id"
											)}
										>
											{regionList && (
												<Fragment>
													<Option />
													{regionList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "transaction_currency") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "transaction_currency"],
					key: "transaction_currency",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={item}
												onChange={onChangePickListSetColumnValueVendorTaskDetails(
													"transaction_currency",
													"transaction_currency_id"
												)}
												optionFilterProp="children"
											>
												{currencyList &&
													currencyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"transaction_currency",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"transaction_currency",
															"transaction_currency_id",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={item}
											onSelect={onChangeSelector(
												"transaction_currency",
												record,
												true,
												"transaction_currency_id"
											)}
											optionFilterProp="children"
											allowClear
											onClear={onClearSelector(
												"transaction_currency",
												record,
												true,
												"transaction_currency_id"
											)}
										>
											{currencyList && (
												<Fragment>
													<Option />
													{currencyList.map((item) => (
														<Option
															key={item.g_source_system_id}
															value={item.g_source_system_id}
														>
															{item.value}
														</Option>
													))}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (
				item.api_name == "po_owner" ||
				item.api_name == "po_line_id" ||
				item.api_name == "po_id" ||
				item.api_name == "po_header_description" ||
				item.api_name == "po_header_amount"
			) {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", item.api_name],
					key: item.api_name,
					width: 180,
					render: (value, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={value}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													item.api_name
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														item.api_name,
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															item.api_name,
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={value}
											onPressEnter={onPressEnterField(
												item.api_name,
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(value, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_line_status") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "po_line_status"],
					key: "po_line_status",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_line_status"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_line_status",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_line_status",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_line_status",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "service_start_date") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "service_start_date"],
					key: "service_start_date",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<DatePicker
												defaultValue={item && moment(item)}
												onChange={onChangeDatePickerColumnValueVendorTaskDetails(
													"service_start_date"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"service_start_date",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"service_start_date",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<div>
											<DatePicker
												defaultValue={item && moment(item)}
												onChange={onChangeDatePicker(
													"service_start_date",
													record
												)}
											/>
										</div>
									)}
								</>
							) : (
								grayLabel(
									item && moment(item).format("YYYY-MM-DD"),
									!isEditable(record)
								)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "service_end_date") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "service_end_date"],
					key: "service_end_date",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<DatePicker
												defaultValue={item && moment(item)}
												onChange={onChangeDatePickerColumnValueVendorTaskDetails(
													"service_end_date"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"service_end_date",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"service_end_date",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<div>
											<DatePicker
												defaultValue={item && moment(item)}
												onChange={onChangeDatePicker(
													"service_end_date",
													record
												)}
											/>
										</div>
									)}
								</>
							) : (
								grayLabel(
									item && moment(item).format("YYYY-MM-DD"),
									!isEditable(record)
								)
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_line_amount") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "po_line_amount"],
					key: "po_line_amount",
					align: "right",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_line_amount"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_line_amount",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_line_amount",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_line_amount",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "invoiced_amount") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "invoiced_amount"],
					key: "invoiced_amount",
					align: "right",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"invoiced_amount"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"invoiced_amount",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"invoiced_amount",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"invoiced_amount",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_line_amount_cancelled") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "po_line_amount_cancelled"],
					key: "po_line_amount_cancelled",
					align: "right",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_line_amount_cancelled"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_line_amount_cancelled",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_line_amount_cancelled",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_line_amount_cancelled",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_balance") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", "po_balance"],
					key: "po_balance",
					align: "right",
					width: 180,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_balance"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_balance",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_balance",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_balance",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								grayLabel(item, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "task_type") {
				columns.push({
					title: item.label,
					dataIndex: "task_type",
					key: "task_type",
					width: 180,
					render: (item) => (
						<Fragment>
							<TableTag
								color={VendorTaskTypeEnum.getColorFromKey(item)}
								background={VendorTaskTypeEnum.getBgColorFromKey(item)}
								name={item}
							/>
						</Fragment>
					),
				});
			} else if (item.api_name == "tx_name") {
				columns.push({
					title: item.label,
					dataIndex: "tx_name",
					key: "tx_name",
					width: 250,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputField("tx_name")}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"tx_name",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"tx_name",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											// onChange={onChangeField("g_transaction_group", record)}
											onPressEnter={onPressEnterField("tx_name", record)}
										/>
									)}
								</>
							) : (
								item
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "calculated_accrual_form") {
				columns.push({
					title: item.label,
					dataIndex: "calculated_accrual_form",
					key: "calculated_accrual_form",
					width: 180,
					render: (data, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={data}
												onChange={onChangePickListSetColumnValue(
													"calculated_accrual_form"
												)}
											>
												{formList && (
													<Fragment>
														{formList.map((item) => {
															let option;
															if (item.app.app_key == "Calculated") {
																option = (
																	<Option key={item.id} value={item.value}>
																		{item.value}
																	</Option>
																);
															}
															return option;
														})}
													</Fragment>
												)}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"calculated_accrual_form",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"calculated_accrual_form",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={data}
											onSelect={onChangeSelector(
												"calculated_accrual_form",
												record
											)}
											allowClear
											onClear={onClearSelector(
												"calculated_accrual_form",
												record
											)}
										>
											{formList && (
												<Fragment>
													<Option />
													{formList.map((item) => {
														let option;
														if (item.app.app_key == "Calculated") {
															option = (
																<Option key={item.id} value={item.value}>
																	{item.value}
																</Option>
															);
														}
														return option;
													})}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								data
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "external_accrual_form") {
				columns.push({
					title: item.label,
					dataIndex: "external_accrual_form",
					key: "external_accrual_form",
					width: 180,
					render: (data, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={data}
												onChange={onChangePickListSetColumnValue(
													"external_accrual_form"
												)}
											>
												{formList && (
													<Fragment>
														{formList.map((item) => {
															let option;
															if (item.app.app_key == "External") {
																option = (
																	<Option key={item.id} value={item.value}>
																		{item.value}
																	</Option>
																);
															}
															return option;
														})}
													</Fragment>
												)}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"external_accrual_form",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"form_name",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={data}
											onSelect={onChangeSelector(
												"external_accrual_form",
												record
											)}
											allowClear
											onClear={onClearSelector("external_accrual_form", record)}
										>
											{formList && (
												<Fragment>
													<Option />
													{formList.map((item) => {
														let option;
														if (item.app.app_key == "External") {
															option = (
																<Option key={item.id} value={item.value}>
																	{item.value}
																</Option>
															);
														}
														return option;
													})}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								data
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "internal_accrual_form") {
				columns.push({
					title: item.label,
					dataIndex: item.api_name,
					key: item.api_name,
					width: 180,
					render: (data, record) => (
						<Fragment>
							{editKey === record.id ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Select
												showSearch
												defaultValue={data}
												onChange={onChangePickListSetColumnValue(
													"internal_accrual_form"
												)}
											>
												{formList && (
													<Fragment>
														{formList.map((item) => {
															let option;
															if (item.app.app_key == "Internal") {
																option = (
																	<Option key={item.id} value={item.value}>
																		{item.value}
																	</Option>
																);
															}
															return option;
														})}
													</Fragment>
												)}
											</Select>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"internal_accrual_form",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRows(
															"form_name",
															record,
															item
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Select
											showSearch
											defaultValue={data}
											onSelect={onChangeSelector(
												"internal_accrual_form",
												record
											)}
											allowClear
											onClear={onClearSelector("internal_accrual_form", record)}
										>
											{formList && (
												<Fragment>
													<Option />
													{formList.map((item) => {
														let option;
														if (item.app.app_key == "Internal") {
															option = (
																<Option key={item.id} value={item.value}>
																	{item.value}
																</Option>
															);
														}
														return option;
													})}
												</Fragment>
											)}
										</Select>
									)}
								</>
							) : (
								data
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_number") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", item.api_name],
					key: item.api_name,
					width: 250,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_number"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_number",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_number",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_number",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								item && grayLabel(item, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "po_line_description") {
				columns.push({
					title: item.label,
					dataIndex: ["vendor_task_details", item.api_name],
					key: item.api_name,
					width: 250,
					render: (item, record) => (
						<Fragment>
							{editKey === record.id && isPOEditable(record) ? (
								<>
									{selectedRows.length > 1 && isRowSelected(record) ? (
										<div className="bulk-column">
											<Input
												defaultValue={item}
												onChange={onChangeInputFieldColumnValueVendorTaskDetails(
													"po_line_description"
												)}
											/>
											<br />
											<div className="checkbox-lbl">
												<Checkbox
													defaultChecked={false}
													onChange={onChangeCheckboxApplyToSelectedRow(
														"po_line_description",
														record.id
													)}
												/>
												<label>
													Update {selectedRows.length} selected items
												</label>
											</div>
											<Fragment>
												<div className="bulk-buttons">
													<Button size="small" onClick={onClickCancel} ghost>
														Cancel
													</Button>
													<Button
														type="primary"
														size="small"
														onClick={onClickApplyChangesToSelectedRowsVendorTaskDetails(
															"po_line_description",
															"",
															record
														)}
													>
														Apply
													</Button>
												</div>
											</Fragment>
										</div>
									) : (
										<Input
											defaultValue={item}
											onPressEnter={onPressEnterField(
												"po_line_description",
												record,
												true
											)}
										></Input>
									)}
								</>
							) : (
								item && grayLabel(item, !isPOEditable(record))
							)}
						</Fragment>
					),
				});
			} else if (item.api_name == "created_at") {
				columns.push({
					title: item.label,
					dataIndex: "created_at",
					key: "created_at",
					width: 180,
					render: (item) => (
						<Fragment>
							<span>
								{grayLabel(
									item && moment(item).format("YYYY-MM-DD h:mm a"),
									true
								)}
							</span>
						</Fragment>
					),
				});
			} else if (item.api_name == "updated_at") {
				columns.push({
					title: item.label,
					dataIndex: "updated_at",
					key: "updated_at",
					width: 180,
					render: (item) => (
						<Fragment>
							<span>
								{grayLabel(
									item && moment(item).format("YYYY-MM-DD h:mm a"),
									true
								)}
							</span>
						</Fragment>
					),
				});
			} else {
				if (item.source == "vendor_task_details") {
					columns.push({
						title: item.label,
						dataIndex: ["vendor_task_details", item.api_name],
						key: item.api_name,
						width: 200,
						render: (item) => (
							<Fragment>
								<span>{grayLabel(item, true)}</span>
							</Fragment>
						),
					});
				} else {
					columns.push({
						title: item.label,
						dataIndex: item.api_name,
						key: item.api_name,
						width: 200,
						render: (item) => (
							<Fragment>
								<span>{grayLabel(item, true)}</span>
							</Fragment>
						),
					});
				}
			}

			return columns;
		});

		return columns;
	};

	const isPOEditable = (record) => {
		if (
			record.task_type === "Vendor" ||
			record.task_type === "Vendor Copy" ||
			record.task_type === "PO Copy"
		) {
			return true;
		}
		return false;
	};

	/* Start: A C T I O N  B U T T O N S */
	const onClickRemove = (item) => async () => {
		deleteVendorTasks(companyId, token, item.id).then((res) => {
			if (res) {
				let tempList = tasks;
				let index = tempList.indexOf(item);

				if (index > -1) {
					tempList = tempList.filter((data, idx) => idx !== index);
					dispatch(setTasks(tempList));
					message.success("Record was successfully removed.");
				}
			} else {
				message.error("Error in removing the record.");
			}
		});
	};

	const onClickHistory = (item) => () => {
		console.log("history", item);
	};

	const onClickCopy = (item, record) => () => {
		copyVendorTasks(item.id).then((res) => {
			if (res) {
				let tempList = tasks;
				let total = parseInt(totalTasks) + 1;
				let index = tasks.indexOf(record);
				if (index > -1) {
					tempList = addAfter(tempList, index + 1, res.data); //insert below item

					if (tempList.length == 101 && index == tempList.length - 2) {
						//check if copying last item of page and if it is already the max item per page
						setTotalItemPerPage(tempList.length); //update totalItem per page to insert temp the item
					} else if (tempList.length > 100) {
						tempList.pop();
					}
					dispatch(setTotalTasks(total));
					dispatch(setTasks(tempList));
					message.success("Record was successfully copied.");
				}
			} else {
				message.error("Error in copying the record.");
			}
		});
	};

	const addAfter = (array, index, newItem) => {
		return [...array.slice(0, index), newItem, ...array.slice(index)];
	};

	const onClearSelector = (key, row, isDetails, keyId) => () => {
		let vendorData = {};
		let detailsData = {};
		let rowId = row.id;

		if (isDetails) {
			let detailsId = row.vendor_task_details.id;
			if (key === "name") {
				vendorData = {
					[keyId]: null,
				};

				detailsData = {
					[key]: null,
				};

				row.vendor_task_details[key] = null;
				row.vendor_id = null;
				updateVendorTasks(companyId, token, rowId, vendorData).then(
					(response) => {
						if (response && response.data) {
							updateVendorDetailsTasks(
								companyId,
								token,
								detailsId,
								detailsData
							).then((res) => {
								if (res.data) {
									message.success("Record was successfully updated.");
								} else {
									message.error("Error in updating the record.");
								}
							});
						} else {
							message.error("Error in updating the record.");
						}
					}
				);
			} else {
				detailsData = {
					[key]: null,
					[keyId]: null,
				};

				row.vendor_task_details[key] = null;
				row.vendor_task_details[keyId] = null;
			}
			if (key !== "name") {
				updateVendorDetailsTasks(companyId, token, detailsId, detailsData).then(
					(res) => {
						if (res && res.data) {
							message.success("Record was successfully updated.");
							if (key == "subsidiary")
								getTaskList({ page: currPage, ...taskParams }); // to fetch subsidiary currency onchange
						} else {
							message.error("Error in updating the record.");
						}
					}
				);
			}
		} else {
			vendorData = {
				[key]: null,
			};
			row[key] = null;

			updateVendorTasks(companyId, token, rowId, vendorData).then(
				(response) => {
					console.log("response", response);
					if (response && response.data) {
						message.success("Record was successfully updated.");
					} else {
						message.error("Error in updating the record.");
					}
				}
			);
		}
	};

	const onChangeSelector = (key, row, isDetails, keyId) => (value, option) => {
		let vendorData = {};
		let detailsData = {};
		let rowId = row.id;

		if (isDetails) {
			let detailsId = row.vendor_task_details.id;
			if (key === "name") {
				vendorData = {
					[keyId]: option.key,
				};

				detailsData = {
					[key]: value,
				};

				row.vendor_task_details[key] = value;
				row.vendor_id = option.key;
				console.log("");
				updateVendorTasks(companyId, token, rowId, vendorData).then(
					(response) => {
						if (response && response.data) {
							updateVendorDetailsTasks(
								companyId,
								token,
								detailsId,
								detailsData
							).then((res) => {
								if (res.data) {
									message.success("Record was successfully updated.");
								} else {
									message.error("Error in updating the record.");
								}
							});
						} else {
							message.error("Error in updating the record.");
						}
					}
				);
			} else {
				detailsData = {
					[key]: option.children == undefined ? null : option.children, // for choosing empty
					[keyId]: option.value,
				};

				row.vendor_task_details[key] = option.children;
				row.vendor_task_details[keyId] = option.value;
			}
			if (key !== "name") {
				updateVendorDetailsTasks(companyId, token, detailsId, detailsData).then(
					(res) => {
						if (res && res.data) {
							message.success("Record was successfully updated.");
							if (key == "subsidiary")
								getTaskList({ page: currPage, ...taskParams }); // to fetch subsidiary currency onchange
						} else {
							message.error("Error in updating the record.");
						}
					}
				);
			}
		} else {
			vendorData = {
				[key]: value,
			};
			row[key] = value;

			updateVendorTasks(companyId, token, rowId, vendorData).then(
				(response) => {
					console.log("response", response);
					if (response && response.data) {
						message.success("Record was successfully updated.");
					} else {
						message.error("Error in updating the record.");
					}
				}
			);
		}
	};

	const onChangeCheckbox = (key, row) => (e) => {
		let value = e.target.checked;
		row[key] = value ? "1" : "0";

		let data = {
			[key]: value ? 1 : 0,
		};
		updateVendorTasks(companyId, token, row.id, data).then((res) => {
			if (res.data) {
				message.success("Record was successfully updated.");
			} else {
				message.error("Error in updating the record.");
			}
		});
	};

	const onPressEnterField = (key, row, isDetails) => (e) => {
		let value = e.target.value;

		let data = {
			[key]: value,
		};

		if (isDetails) {
			let detailsId = row.vendor_task_details.id;
			row.vendor_task_details[key] = value;
			updateVendorDetailsTasks(companyId, token, detailsId, data).then(
				(response) => {
					if (response && response.data) {
						message.success("Record was successfully updated.");
					} else {
						message.error("Error in updating the record.");
					}
				}
			);
		} else {
			let rowId = row.id;
			row[key] = value;
			updateVendorTasks(companyId, token, rowId, data).then((res) => {
				if (res && res.data) {
					message.success("Record was successfully updated.");
				} else {
					message.error("Error in updating the record.");
				}
			});
		}
	};

	const onChangeDatePicker = (key, row) => (date) => {
		let detailsId = row.vendor_task_details.id;

		let data = {
			[key]: moment(date).format("YYYY-MM-DD"),
		};
		row.vendor_task_details[key] = date;
		console.log("detailsId", detailsId, data);
		updateVendorDetailsTasks(companyId, token, detailsId, data).then(
			(response) => {
				if (response && response.data) {
					message.success("Record was successfully updated.");
				} else {
					message.error("Error in updating the record.");
				}
			}
		);
	};

	const onChangeTable = async (pagination) => {
		let curPage = pagination.current;
		let params = {
			...taskParams,
			page: curPage,
		};

		setApplyToSelectedRows([]);
		setSelectedRows([]);
		setEditKey("");
		dispatch(setCurrPage(curPage));
		dispatch(setIsLoading(true));
		getTaskList(params);
	};

	const getUserAccessRole = async () => {
		for (var pair of roles) {
			if (pair[0].includes("userRoles")) {
				if (pair[1] === "Task Manager") setHasEditRole(true);
			}
		}
	};

	/* Ascending Order Sorting based on value */
	//const sortAsc = (optionA, optionB) => (optionA.value > optionB.value) ? 1 : -1;

	/* Case Insensitive Sorting */
	const sortAsc = function (optionA, optionB) {
		if (optionA.value && optionB.value) {
			return optionA.value
				.toLowerCase()
				.localeCompare(optionB.value.toLowerCase());
		} else {
			return 0;
		}
	};

	/* Start: A P I  C A L L S */
	const getTaskList = async (params) => {
		const response = await getVendorTasks(params);
		console.log("page", response, params);
		if (response && response.data) {
			setTotalItemPerPage(100);
			dispatch(setTasks(response.data));
			dispatch(setTotalTasks(response.headers["x-total-count"]));
			dispatch(setIsLoading(false));
			setShowHeader(true);
		} else {
			dispatch(setTasks([]));
			dispatch(setIsLoading(false));
			setShowHeader(false);
		}
	};

	const getVendorList = async () => {
		const response = await getSourceVendorOptions(companyId, token);
		if (response && response.data) {
			setVendorList(response.data.sort(sortAsc));
		} else {
			setVendorList([]);
		}
	};

	const getFormList = async () => {
		const response = await getFormsOptions(companyId, token);

		if (response && response.data) {
			setFormList(response.data.sort(sortAsc));
		}
	};

	const getCompanyList = async () => {
		const response = await getSourceCoaOptions("Company");

		if (response && response.data && response.data.Company) {
			setCompanyList(response.data.Company.sort(sortAsc));
		}
	};

	const getSubsidiaryList = async () => {
		const response = await getSourceCoaOptions("Subsidiary");

		if (response && response.data && response.data.Subsidiary) {
			console.log("Subsidiary", response.data.Subsidiary);
			setSubsidiaryList(response.data.Subsidiary.sort(sortAsc));
		}
	};

	const getDeptList = async () => {
		const response = await getSourceCoaOptions("Department");

		if (response && response.data && response.data.Department) {
			setDeptList(response.data.Department.sort(sortAsc));
		}
	};

	const getGlAccountList = async () => {
		const response = await getSourceCoaOptions("GLAccount");

		if (response && response.data && response.data.GLAccount) {
			setGlAccountList(response.data.GLAccount.sort(sortAsc));
		}
	};

	const getInterCompanyList = async () => {
		const response = await getSourceCoaOptions("InterCompany");

		if (response && response.data && response.data.Intercompany) {
			setInterCompanyList(response.data.Intercompany.sort(sortAsc));
		}
	};

	const getChannelList = async () => {
		const response = await getSourceCoaOptions("Channel");

		if (response && response.data && response.data.Channel) {
			setChannelList(response.data.Channel.sort(sortAsc));
		}
	};

	const getLocationList = async () => {
		const response = await getSourceCoaOptions("Location");

		if (response && response.data && response.data.Location) {
			setLocationList(response.data.Location.sort(sortAsc));
		}
	};

	const getProductList = async () => {
		const response = await getSourceCoaOptions("Product");

		if (response && response.data && response.data.Product) {
			setProductList(response.data.Product.sort(sortAsc));
		}
	};

	const getRegionList = async () => {
		const response = await getSourceCoaOptions("Region");

		if (response && response.data && response.data.Region) {
			setRegionList(response.data.Region.sort(sortAsc));
		}
	};

	const getCurrencyList = async () => {
		const response = await getSourceCoaOptions("Currency");

		if (response && response.data && response.data.Currency) {
			setCurrencyList(response.data.Currency.sort(sortAsc));
		}
	};

	const getClassList = async () => {
		const response = await getSourceCoaOptions("Class");

		if (response && response.data && response.data.Class) {
			setClassList(response.data.Class.sort(sortAsc));
		}
	};

	const getProjectList = async () => {
		const response = await getSourceCoaOptions("Project");

		if (response && response.data && response.data.Project) {
			setProjectList(response.data.Project.sort(sortAsc));
		}
	};

	const getExternalList = async () => {
		const response = await getSourceCoaOptions("External");

		if (response && response.data && response.data.External) {
			setExternalList(response.data.External.sort(sortAsc));
		}
	};

	const getInternalList = async () => {
		const response = await getSourceCoaOptions("Internal");

		if (response && response.data && response.data.Internal) {
			setInternalList(response.data.Internal.sort(sortAsc));
		}
	};

	const authenticateTokenCompanyId = async () => {
		const response = await getAuthVendorTask(companyId, token);
		if (response) {
			getUserAccessRole();
			getTaskList();
			getVendorList();
			getFormList();
			getSubsidiaryList();
			getDeptList();
			getGlAccountList();
			getCompanyList();
			getProductList();
			getRegionList();
			getLocationList();
			getChannelList();
			getInterCompanyList();
			getClassList();
			getCurrencyList();
			getProjectList();
			getColumnHeaders();
			getExternalList();
			getInternalList();
		}
	};

	useEffect(() => {
		authenticateTokenCompanyId(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeCheckboxAll = (rows) => (e) => {
		const isChecked = e.target.checked;

		if (!isChecked) {
			setSelectedRows([]);
			return;
		}
		setSelectedRows(rows.map((item) => item.id));
	};

	const onChangeSelectCheckbox = (row) => (e) => {
		const isChecked = e.target.checked;

		if (!isChecked) {
			setSelectedRows(selectedRows.filter((item) => item !== row.id));
			return;
		}
		setSelectedRows([...selectedRows, row.id]);
	};

	const isRowSelected = (row) => selectedRows.includes(row.id);

	const onChangeSetColumnValue = (key) => (e) => {
		const value = e.target.value;
		setColumnValue({ key, value });
	};

	const onChangePickListSetColumnValue = (key) => (value) => {
		setColumnValue({ key, value });
	};

	const onChangePickListSetColumnValueVendorTaskDetails =
		(key, keyId) => (value, option) => {
			setColumnValueTaskDetails({
				[key]: option.children,
				[keyId]: value,
			});
		};

	const onChangeColumnVendorName = (key, keyId) => (value, option) => {
		setColumnVendorName({
			[keyId]: option.key,
			[key]: value,
		});
	};

	const onChangeInputFieldColumnValueVendorTaskDetails =
		(key, keyId) => (e) => {
			const value = e.target.value;
			if (keyId) {
				//
			}
			setColumnValueTaskDetails({ [key]: value });
		};

	const onChangeDatePickerColumnValueVendorTaskDetails =
		(key, keyId) => (date) => {
			const value = moment(date).format("YYYY-MM-DD");
			if (keyId) {
				//
			}
			setColumnValueTaskDetails({ [key]: value });
		};

	const onChangeInputField = (key) => (e) => {
		const value = e.target.value;
		setColumnValue({ key, value });
	};

	const onChangeCheckboxApplyToSelectedRow = (key, id) => (e) => {
		const itemExists = applyToSelectedRows.find(
			(item) => item.id === id && item.key === key
		);

		if (applyToSelectedRows.length < 1 || !Boolean(itemExists)) {
			const row = {
				id,
				key,
				selectAll: e.target.checked,
			};
			setApplyToSelectedRows([...applyToSelectedRows, row]);
			return;
		}

		setApplyToSelectedRows(
			applyToSelectedRows.filter((row) => row.key !== key)
		);
	};

	const onClickApplyChangesToSelectedRows =
		(key, row, itemData) => async () => {
			const value = columnValue.value === null ? itemData : columnValue.value;

			const dataKey = columnValue.key === "" ? key : columnValue.key;

			const getRowField = applyToSelectedRows.find(
				(item) => item.id == row.id && item.key == key
			);

			if (Boolean(getRowField)) {
				const selectedEditableRows = hasEditableKeys.includes(key)
					? selectedRows.filter((item) => {
							const task = tasks.find((task) => task.id === item);
							if (key == "internal_email" || key == "internal_cc_email") {
								return isEditable(task);
							}
							return isEditable(task) && !task.internal_source_vendor_id;
					  })
					: selectedRows;

				const data = {
					ids: selectedEditableRows,
					[dataKey]: value,
				};

				try {
					dispatch(setIsLoading(true));
					await massUpdateVendorTasks(data);
					await getTaskList({ page: currPage, ...taskParams });
					setSelectedRows([]);
					setColumnValue({ key: "", value: null });
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (err) {
					dispatch(setIsLoading(false));
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
				}
			} else {
				const data = {
					[dataKey]: value,
				};

				try {
					dispatch(setIsLoading(true));
					await updateVendorTasks(companyId, token, row.id, data);
					await getTaskList({ page: currPage, ...taskParams });
					setSelectedRows([]);
					setColumnValue({ key: "", value: null });
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (err) {
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
				}
			}
		};

	const onClickApplyChangesToSelectedRowsVendorTaskDetails =
		(key, keyId, row) => async () => {
			const item = {
				[key]: row.vendor_task_details[key],
			};

			if (keyId) {
				item[keyId] = row.vendor_task_details[keyId];
			}

			const value =
				Object.keys(columnValueVendorTaskDetails).length === 0
					? item
					: columnValueVendorTaskDetails;

			const getRowField = applyToSelectedRows.find(
				(item) => item.id == row.id && item.key == key
			);

			if (Boolean(getRowField)) {
				const selectedEditableRows = hasEditableKeys.includes(key)
					? selectedRows.filter((item) => {
							const task = tasks.find((task) => task.id === item);
							return isEditable(task);
					  })
					: selectedRows;

				const detailsId = selectedEditableRows.map((item) => {
					const task = tasks.find((task) => task.id === item);
					return task.vendor_task_details.id ?? task.vendor_task_details.id;
				});

				const data = {
					ids: detailsId,
					...value,
				};

				try {
					dispatch(setIsLoading(true));
					await massUpdateVendorTasksDetails(data);
					await getTaskList({ page: currPage, ...taskParams });
					setSelectedRows([]);
					setColumnValue({ key: "", value: null });
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (err) {
					dispatch(setIsLoading(false));
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
				}
			} else {
				const data = { ...value };

				try {
					dispatch(setIsLoading(true));
					await updateVendorDetailsTasks(companyId, token, row.id, data);
					await getTaskList({ page: currPage, ...taskParams });
					setSelectedRows([]);
					setColumnValue({ key: "", value: null });
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (err) {
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
				}
			}
		};

	const onClickApplyChangesToSelectedRowsVendorName =
		(key, keyId, row) => async () => {
			const vendorId =
				columnVendorName.vendor_id === null
					? row.vendor_id
					: columnVendorName.vendor_id;

			const vendorName =
				columnVendorName.name === ""
					? row.vendor_task_details.name
					: columnVendorName.name;

			const getRowField = applyToSelectedRows.find(
				(item) => item.id == row.id && item.key == key
			);
			if (Boolean(getRowField)) {
				const selectedEditableRows = hasEditableKeys.includes(key)
					? selectedRows.filter((item) => {
							const task = tasks.find((task) => task.id === item);
							return isEditable(task) && !task.internal_source_vendor_id;
					  })
					: selectedRows;

				const detailsIds = selectedEditableRows.map((item) => {
					const task = tasks.find((task) => task.id === item);
					return task.vendor_task_details.id ?? task.vendor_task_details.id;
				});

				const vendorData = {
					ids: selectedEditableRows,
					vendor_id: vendorId,
				};

				const vendorDetailsData = {
					ids: detailsIds,
					name: vendorName,
				};

				try {
					dispatch(setIsLoading(true));
					await massUpdateVendorTasks(vendorData);
					await massUpdateVendorTasksDetails(vendorDetailsData);
					await getTaskList({ page: currPage, ...taskParams });
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (error) {
					dispatch(setIsLoading(false));
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
					setColumnVendorName({ name: "", vendor_id: null });
					setSelectedRows([]);
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
				}
			} else {
				const vendorData = {
					vendor_id: vendorId,
				};

				const vendorDetailsData = {
					name: vendorName,
				};

				try {
					dispatch(setIsLoading(true));
					await updateVendorTasks(companyId, token, row.id, vendorData);
					await updateVendorDetailsTasks(
						companyId,
						token,
						row.id,
						vendorDetailsData
					);
					await getTaskList({ page: currPage, ...taskParams });
					dispatch(setIsLoading(false));
					message.success("Record was successfully updated.");
				} catch (error) {
					dispatch(setIsLoading(false));
					message.error("Error in updating the record.");
				} finally {
					setEditKey("");
					setColumnVendorName({ name: "", vendor_id: null });
					setSelectedRows([]);
					setColumnValueTaskDetails({});
					setApplyToSelectedRows([]);
				}
			}
		};

	const onClickCancel = () => {
		setEditKey("");
		setColumnVendorName({ name: "", vendor_id: null });
		setColumnValueTaskDetails({});
		setApplyToSelectedRows([]);
	};

	return (
		<Fragment>
			<div className="table-content-wrapper">
				<TaskManagerFilter
					columns={columnHeaders}
					externalOptions={externalList}
					internalOptions={internalList}
				/>
				<DyanmicTable
					columnHeader={setColumns()}
					data={tasks}
					scroll={{ x: "max-content", y: 600 }}
					loading={isLoading}
					onRow={(record) => {
						return {
							onDoubleClick: () => {
								if (hasEditRole) {
									setEditKey(record.id);
								}
							},

							onClick: () => {
								if (editKey != record.id) {
									setEditKey("");
									setApplyToSelectedRows([]);
									setColumnValue({ key: "", value: null });
									setColumnValueTaskDetails({});
								}
							},
						};
					}}
					pagination={{
						pageSize: totalItemPerPage,
						total: totalTasks,
						showSizeChanger: false,
						defaultCurrent: 1,
						current: currPage,
					}}
					onChange={onChangeTable}
					showHeader={showHeader}
				/>
			</div>
		</Fragment>
	);
};

export default TaskManagerTable;
