import _ from "underscore";

class SendStatusEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}

const toReturn = {
	SENT: new SendStatusEnum("Sent", "Sent", "#00a3ed", "#ddf4ff"),
	DELIVERED: new SendStatusEnum("Delivered", "Delivered", "#e6bf00", "#fbf6e1"),
	BOUNCED: new SendStatusEnum("Bounced", "Bounced", "#7f39b2", "#f0e4f9"),
	READ: new SendStatusEnum("Read", "Read", "#51c8c4", "#e2fffe"),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

export default Object.freeze({ ...toReturn, ...methods });
