import _ from "underscore";

class TaskTypeEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}

const toReturn = {
	ALL: new TaskTypeEnum("all", "All", "#00a3ed", "#ddf4ff"),
	VDAC: new TaskTypeEnum("external", "External Accrual", "#e6bf00", "#fbf6e1"),
	OPEN: new TaskTypeEnum("internal", "Internal Accrual", "#7f39b2", "#f0e4f9"),
	MAN: new TaskTypeEnum("man", "Manual", "#51c8c4", "#e2fffe"),
	CALCULATED: new TaskTypeEnum(
		"calculated",
		"Calculated",
		"#51c8c4",
		"#e2fffe"
	),
	CALCULATION: new TaskTypeEnum(
		"calculation",
		"Calculation",
		"#51c8c4",
		"#e2fffe"
	),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

methods.getKeyValueList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

methods.getKeyValueListNoManual = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined && o.key !== "man" && o.key !== "calculation") {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

methods.getKeyValueListNoManualNoAll = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (
			o.key !== undefined &&
			o.key !== "man" &&
			o.key !== "calculation" &&
			o.key !== "all"
		) {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

export default Object.freeze({ ...toReturn, ...methods });
