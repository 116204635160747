import { ActionTypes } from "../constants/action-types";

const initalState = {
	tasks: [],
	totalTasks: 0,
	params: {},
	isLoading: true,
	currPage: 1,
};

export const taskManagerReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_TASKS:
			return { ...state, tasks: payload };

		case ActionTypes.SET_TOTAL_TASKS:
			return { ...state, totalTasks: payload };

		case ActionTypes.SET_TASK_PARAMS:
			return { ...state, taskParams: payload };

		case ActionTypes.SET_IS_LOADING:
			return { ...state, isLoading: payload };

		case ActionTypes.SET_CURR_PAGE:
			return { ...state, currPage: payload };

		default:
			return state;
	}
};
