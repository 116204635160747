import React from "react";
import { Select } from "antd";
const { Option } = Select;

const Selector = ({
	label,
	defaultValue,
	options,
	style,
	showSearch,
	key,
	valueKey,
	nameKey,
	onChange,
	placeholder,
}) => {
	return (
		<div className="selector-container">
			{label && <div className="input-lbl">{label}</div>}
			<Select
				defaultValue={defaultValue}
				style={style}
				onChange={onChange}
				showSearch={showSearch}
				placeholder={placeholder}
			>
				{options &&
					options.map((item, i) => (
						<Option
							key={key ? item[key] : i}
							value={valueKey ? item[valueKey] : item.value}
							disabled={item.disabled}
						>
							{nameKey ? item[nameKey] : item.name}
						</Option>
					))}
			</Select>
		</div>
	);
};
export default Selector;
