import { ActionTypes } from "../constants/action-types";

export const setTasks = (tasks) => {
	return {
		type: ActionTypes.SET_TASKS,
		payload: tasks,
	};
};

export const setTotalTasks = (total) => {
	return {
		type: ActionTypes.SET_TOTAL_TASKS,
		payload: total,
	};
};

export const setTaskParams = (params) => {
	return {
		type: ActionTypes.SET_TASK_PARAMS,
		payload: params,
	};
};

export const setIsLoading = (loading) => {
	return {
		type: ActionTypes.SET_IS_LOADING,
		payload: loading,
	};
};

export const setCurrPage = (currPage) => {
	return {
		type: ActionTypes.SET_CURR_PAGE,
		payload: currPage,
	};
};
