import {
	axiosIstance,
	axiosInstanceTaskManager,
	axiosInstanceTaskScheduler,
} from "../helpers/axios";

export const getEmails = (
	customerId,
	emailSenderId,
	endDate,
	startDate,
	size,
	page
) => {
	let params = {
		customerId: customerId,
		emailSenderId: emailSenderId,
		endDate: endDate,
		startDate: startDate,
		size: size,
		page: page,
	};

	return axiosIstance.get("/emails", { params }).catch((err) => {
		console.log(err);
	});
};

export const getCustomerId = (companyId, token, emailSenderId) => {
	let params = {
		companyId: companyId,
		userToken: token,
		emailSenderId: emailSenderId,
	};

	return axiosIstance.get("me", { params }).catch((err) => {
		console.log(err);
	});
};

export const getVendorTasks = (params) => {
	return axiosInstanceTaskManager
		.get("api/vendor-tasks", { params })
		.catch((err) => {
			console.log(err);
		});
};

export const updateVendorTasks = (cid, token, row_id, data) => {
	return axiosInstanceTaskManager
		.put(`api/vendor-tasks/${row_id}`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const updateVendorDetailsTasks = (cid, token, row_id, data) => {
	return axiosInstanceTaskManager
		.put(`api/vendor-tasks/details/${row_id}`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const deleteVendorTasks = (cid, token, row_id) => {
	return axiosInstanceTaskManager
		.delete(`api/vendor-tasks/${row_id}`)
		.catch((err) => {
			console.log(err);
		});
};

export const createVendorTasks = (cid, token, data) => {
	return axiosInstanceTaskManager
		.post("api/vendor-tasks", data)
		.catch((err) => {
			console.log(err);
		});
};

export const copyVendorTasks = (row_id) => {
	let params = {
		id: row_id,
	};

	return axiosInstanceTaskManager
		.post("api/vendor-tasks/copy-from-vendor-task", params)
		.catch((err) => {
			console.log(err);
		});
};

export const createVendorDetailsTasks = (cid, token, data) => {
	return axiosInstanceTaskManager
		.post("api/vendor-tasks/details", data)
		.catch((err) => {
			console.log(err);
		});
};

export const getSourceCoaOptions = (type) => {
	const params = {
		type: type,
	};
	return axiosInstanceTaskManager
		.get("api/source/chart-of-accounts", { params })
		.catch((err) => {
			console.log(err);
		});
};

export const getSourceVendorOptions = () => {
	return axiosInstanceTaskManager.get("api/source/vendors").catch((err) => {
		console.log(err);
	});
};

export const getFormsOptions = () => {
	return axiosInstanceTaskManager.get("/api/source/forms").catch((err) => {
		console.log(err);
	});
};

export const getTaskSchedules = (
	customerId,
	endDate,
	startDate,
	size,
	page,
	envName
) => {
	let params = {
		customerId: customerId,
		endDate: endDate,
		startDate: startDate,
		size: size,
		page: page,
		envName: envName,
	};

	return axiosInstanceTaskScheduler
		.get("task-schedules", { params })
		.catch((err) => {
			console.log(err);
		});
};

export const createTaskSchedule = (data) => {
	return axiosInstanceTaskScheduler
		.post("task-schedules", data)
		.catch((err) => {
			console.log(err);
		});
};

export const updateSchedule = (id, data) => {
	return axiosInstanceTaskScheduler
		.patch(`task-schedules/${id}`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const getAuthTaskScheduler = (companyId, token, envName) => {
	let params = {
		companyId: companyId,
		userToken: token,
		envName: envName ? envName : "", //if no passed envName in URL to default to qa
	};

	return axiosInstanceTaskScheduler.get("/me", { params }).catch((err) => {
		console.log(err);
	});
};

export const getAuthVendorTask = (companyId, token) => {
	let params = {
		customerId: companyId,
		token: token,
		withCredentials: true,
	};

	return axiosInstanceTaskManager.get("api/me", { params }).catch((err) => {
		console.log(err);
	});
};

export const massUpdateVendorTasks = (data) => {
	return axiosInstanceTaskManager
		.put(`api/vendor-tasks/mass-update`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const massUpdateVendorTasksDetails = (data) => {
	return axiosInstanceTaskManager.put(
		`api/vendor-tasks/details/mass-update`,
		data
	);
};

export const getWorkItems = (runId) => {
	return axiosInstanceTaskScheduler
		.get(`job-work-items/runid/${runId}`)
		.catch((err) => {
			console.log(err);
		});
};

export const updateAllWorkItems = (runId, status) => {
	return axiosInstanceTaskScheduler
		.get(`job-work-items/update/status/runid/${runId}/${status}`)
		.catch((err) => {
			console.log(err);
		});
};

export const updateWorkItem = (id, data) => {
	const config = { headers: { "Content-Type": "application/json" } };
	return axiosInstanceTaskScheduler
		.patch(`job-work-items/update/status/${id}`, data, config)
		.catch((err) => {
			console.log(err);
		});
};

export const getTaskManagerDynamicColumn = () => {
	return axiosInstanceTaskManager
		.get("api/task-manager/settings")
		.catch((err) => {
			console.log(err);
		});
};

// G E N E R A T E  T R A N S A C T I O N
export const createSourceVendor = (data) => {
	return axiosInstanceTaskManager
		.post("api/source/vendors", data)
		.catch((err) => {
			console.log(err);
		});
};

export const createFromSourceVendor = (data) => {
	return axiosInstanceTaskManager
		.post("api/vendor-tasks/create-from-vendor", data)
		.catch((err) => {
			console.log(err);
		});
};

export const updateVendorTaskExternalInternalCalc = (id, data) => {
	return axiosInstanceTaskManager
		.put(`api/vendor-tasks/${id}`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const updateVendorTaskDetailsCalc = (id, data) => {
	return axiosInstanceTaskManager
		.put(`api/vendor-tasks/details/${id}`, data)
		.catch((err) => {
			console.log(err);
		});
};

export const createTransaction = (data) => {
	return axiosInstanceTaskManager
		.post("api/transactions", data)
		.catch((err) => {
			console.log(err);
		});
};

export const getVendorTasksById = (id) => {
	return axiosInstanceTaskManager.get(`api/vendor-tasks/${id}`).catch((err) => {
		console.log(err);
	});
};

export const generateNow = (id) => {
	return axiosInstanceTaskScheduler
		.get(`task-schedules/runnow/${id}`)
		.catch((err) => {
			console.log(err);
		});
};
