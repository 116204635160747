import { Accordion } from "react-bootstrap";

/* eslint-disable jsx-a11y/anchor-is-valid */
const MenuComponent = () => {
	return (
		<div id="nav-container">
			<div id="nav-wrapper">
				<nav>
					<ul className="nav" id="dash_nav">
						<li>
							<a href="">
								<i className="fa fa-dashboard">
									<span className="icon-bg bg-danger"></span>
								</i>
								<span>Dashboard</span>
							</a>
						</li>
						<li>
							<Accordion>
								<Accordion.Header>
									<a
										href="#"
										data-target="#um"
										// data-toggle="collapse"
										// aria-expanded={isOpen}
									>
										<i className="fa fa-user">
											<span className="icon-bg bg-warning"></span>
										</i>
										<span>
											User Management
											<i
												style={{ float: "right" }}
												className="fa fa-caret-right"
											></i>
										</span>
									</a>
								</Accordion.Header>

								<Accordion.Body>
									<ul className="nav" id="um" role="menu">
										<li>
											<a href="">
												<i className="fa fa-caret-right "></i>Access Level
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>Roles
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>Assignable Roles
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>Users
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>Alan Tasks
											</a>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion>
						</li>
						<li>
							<Accordion>
								<Accordion.Header>
									<a href="#" data-target="#ua" data-bs-toggle="collapse">
										<i className="fa fa-archive">
											<span className="icon-bg bg-orange"></span>
										</i>
										<span>
											Apps
											<i
												style={{ float: "right" }}
												className="fa fa-caret-right"
											></i>
										</span>
									</a>
								</Accordion.Header>
								<Accordion.Body>
									<ul className="nav" id="ua" role="menu">
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>
												<span>View All Apps</span>
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>
												<span>App Mngmnt Home</span>
											</a>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion>
						</li>
						<li>
							<a href="">
								<i className="fa fa-th-large">
									<span className="icon-bg bg-primary"></span>
								</i>
								<span>Subscriptions</span>
							</a>
						</li>
						<li>
							<a href="">
								<i className="fa fa-file">
									<span className="icon-bg bg-success"></span>
								</i>
								<span>Reports</span>
							</a>
						</li>
						<li>
							<Accordion>
								<Accordion.Header>
									<a href="#" data-target="#ub" data-toggle="collapse">
										<i className="fa fa-magic">
											<span className="icon-bg bg-orange"></span>
										</i>
										<span>
											Integrations
											<i
												style={{ float: "right" }}
												className="fa fa-caret-right"
											></i>
										</span>
									</a>
								</Accordion.Header>
								<Accordion.Body>
									<ul className="nav " id="ub" role="menu">
										<li>
											<a href="" data-li-title="users">
												<i className="fa fa-caret-right"></i>Scheduler
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>Csv Builder
											</a>
										</li>
										<li>
											<a href="" className="auto-deletion-link">
												<i className="fa fa-caret-right"></i>Auto-Purge
											</a>
										</li>
										<li>
											<a href="">
												<i className="fa fa-caret-right"></i>ACRS IAPI
												<span className="badge badge-danger">beta</span>
											</a>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};
export default MenuComponent;
