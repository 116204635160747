import { combineReducers } from "redux";
import { emailReducer } from "./emailReducer";
import { userReducer } from "./userReducer";
import { taskManagerReducer } from "./taskManagerReducer";
import { taskSchedulerReducer } from "./taskSchedulerReducer";

const reducers = combineReducers({
	allEmails: emailReducer,
	allUsers: userReducer,
	allTasks: taskManagerReducer,
	allTaskScheduler: taskSchedulerReducer,
});

export default reducers;
