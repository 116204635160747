import _ from "underscore";

class CalculationTypeEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}

const toReturn = {
	NONE: new CalculationTypeEnum(1, "None", "#00a3ed", "#ddf4ff"),
	STRAIGHTLINE: new CalculationTypeEnum(
		2,
		"Straightline",
		"#e6bf00",
		"#fbf6e1"
	),
	// ENG-114: Percentage of Completion - Task Manager
	// PERCENTAGE_COMPLETION: new CalculationTypeEnum(
	// 	3,
	// 	"Percentage of Completion",
	// 	"#7f39b2",
	// 	"#f0e4f9"
	// ),
	HISTORICAL: new CalculationTypeEnum(
		4,
		"Historical Average",
		"#51c8c4",
		"#e2fffe"
	),
};

const optionRestrictions = {
	Vendor: ["None", "Historical Average"],
	"Vendor Copy": ["None", "Historical Average"],
	PO: ["None", "Straightline"],
	"PO Copy": ["None", "Straightline"],
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

methods.getKeyValueList = (record) => {
	let keyList = [];
	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			if (methods.filterOptions(record, o)) {
				keyList.push({ id: o.key, value: o.dscp });
			}
		}
	});
	return keyList;
};

methods.getKeyFromDscp = (dscp) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.dscp === dscp) {
			return true;
		}

		return false;
	});
	return found ? found.key : "";
};

/**
 * ENG-1487 - Restrict specific options for calculation dropdown
 *
 * @param {object} record - The record itself
 * @param {object} option - The option based on the obtained values
 * @returns boolean
 */
methods.filterOptions = (record, option) => {
	if (record && option.dscp) {
		let allowed = optionRestrictions[record.task_type];
		if (allowed && !allowed.includes(option.dscp)) {
			return false;
		}
	}
	return true;
};

export default Object.freeze({ ...toReturn, ...methods });
