import { NavDropdown } from "react-bootstrap";
import { Menu } from "antd";
import { useState, useRef, useEffect } from "react";
import "react-bootstrap-submenu/dist/index.css";

const { SubMenu } = Menu;

/* eslint-disable jsx-a11y/anchor-is-valid */
const TopHeader = () => {
	const ref = useRef();
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const onClickMenu = () => {
		setIsOpenMenu(!isOpenMenu);
	};

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isOpenMenu && ref.current && !ref.current.contains(e.target)) {
				setIsOpenMenu(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isOpenMenu]);

	return (
		<section className="top-header">
			<header className="clearfix">
				<div className="logo">
					<a href="/">
						<img src="assets/images/gappify_logo.png" alt="gappify_logo" />
					</a>
				</div>

				<div className="menu-button" toggle-off-canvas>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
				</div>

				<div className="top-nav">
					<ul className="nav-right list-unstyled">
						<li>
							<a id="dLabel" onClick={onClickMenu} ref={ref}>
								<i className="fa fa-th-list"></i>
							</a>
							{isOpenMenu && (
								<Menu
									mode="vertical"
									className="nav-app"
									title={
										<a
											id="dLabel"
											onClick={onClickMenu}
											tabIndex="0"
											onBlur={onClickMenu}
										>
											<i className="fa fa-th-list"></i>
										</a>
									}
								>
									<SubMenu
										key="1"
										title="AR Collection"
										popupClassName="top-nav-sub-menu"
									>
										<Menu.Item key="2">AR Collections</Menu.Item>
										<Menu.Item key="2">AR Collections</Menu.Item>
									</SubMenu>
									<SubMenu
										key="3"
										title="AR Confirmation"
										popupClassName="top-nav-sub-menu"
									>
										<Menu.Item key="4">AR Confirmations</Menu.Item>
									</SubMenu>
									<SubMenu
										key="5"
										title="Facilitate the process of requesting the vendors to update their records."
										popupClassName="top-nav-sub-menu"
									>
										<Menu.Item key="6">CIP-Confirmation</Menu.Item>
									</SubMenu>
								</Menu>
							)}
						</li>

						<li className="dropdown text-normal nav-profile pull-right nav-user">
							<NavDropdown
								title={
									<div>
										<img
											src="assets/images/default.jpg"
											className="img-circle img30_30"
											alt=""
										/>
										<span class="hidden-xs">
											<span class="text-capitalize">
												Gappify Admin ( Customer Admin )
											</span>
										</span>
									</div>
								}
								align="end"
								drop="down"
							>
								<NavDropdown.Item href="">
									<i class="fa fa-wrench"></i>
									<span>Settings</span>
								</NavDropdown.Item>
								<NavDropdown.Item href="">
									<i class="fa fa-sign-out"></i>
									<span>Log Out</span>
								</NavDropdown.Item>
							</NavDropdown>
						</li>
					</ul>
				</div>
			</header>
		</section>
	);
};

export default TopHeader;
