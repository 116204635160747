import React, { Fragment } from "react";
import TaskSchedulerTabs from "../layouts/TaskSchedulerTabs";

const TaskSchedulerContainer = () => {
	return (
		<Fragment>
			<TaskSchedulerTabs />
		</Fragment>
	);
};

export default TaskSchedulerContainer;
