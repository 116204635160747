export const ActionTypes = {
	SET_EMAILS: "SET_EMAILS",
	SET_USER: "SET_USER",
	SET_TASKS: "SET_TASKS",
	SET_TASKS_SCHEDULES: "SET_TASKS_SCHEDULES",
	SET_TOTAL_TASKS: "SET_TOTAL_TASKS",
	SET_TASK_PARAMS: "SET_TASK_PARAMS",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_CURR_PAGE: "SET_CURR_PAGE",
};
