import _ from "underscore";

class VendorTaskTypeEnum {
	constructor(key, dscp, color, bgColor, tag) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
		this.tag = tag;
	}
}

const toReturn = {
	VENDOR: new VendorTaskTypeEnum(
		"Vendor",
		"Vendor",
		"#00a3ed",
		"#ddf4ff",
		"gold"
	),
	INVOICE: new VendorTaskTypeEnum(
		"Invoice",
		"Invoice",
		"#7f39b2",
		"#f0e4f9",
		"lime"
	),
	PO: new VendorTaskTypeEnum("PO", "PO", "#c41d7f", "#fff0f6"),
	VENDOR_COPY: new VendorTaskTypeEnum(
		"Vendor Copy",
		"Vendor Copy",
		"#389e0d",
		"#f6ffed",
		"green"
	),
	INVOICE_COPY: new VendorTaskTypeEnum(
		"Invoice Copy",
		"Invoice Copy",
		"#51c8c4",
		"#e2fffe",
		"cyan"
	),
	PO_COPY: new VendorTaskTypeEnum(
		"PO Copy",
		"PO Copy",
		"#e6bf00",
		"#fbf6e1",
		"blue"
	),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "#abaaaa";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

methods.getKeyValueList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

methods.getKeyValueListNoManual = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined && o.key !== "man" && o.key !== "calculation") {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

methods.getKeyValueListNoManualNoAll = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (
			o.key !== undefined &&
			o.key !== "man" &&
			o.key !== "calculation" &&
			o.key !== "all"
		) {
			keyList.push({ id: o.key, value: o.dscp });
		}
	});
	return keyList;
};

export default Object.freeze({ ...toReturn, ...methods });
