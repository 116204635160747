import React, { useState, Fragment } from "react";
import { Modal, Button, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
	getWorkItems,
	updateAllWorkItems,
	updateWorkItem,
} from "../services/baseAPI";
import DynamicTable from "../components/DynamicTable";
import TableTag from "../components/TableTag";
import moment from "moment";
import { JobRunStatusEnum } from "../utils/enums";

/*eslint eqeqeq: "off"*/
const WorkItemModal = ({ runTimeDate, runTimeId }) => {
	const [workItems, setWorkItems] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [editingKey, setEditingKey] = useState("");

	const header = [
		{
			title: "Action",
			dataIndex: "jobWorkItemId",
			key: "jobWorkItemId",
			fixed: "left",
			width: 100,
			render: (item, record) => (
				<div className="action-popover">
					<Popover
						content={
							<div className="action-menu">
								<div onClick={onClickRetry(item)}>Retry</div>
								<div onClick={onClickSkip(item)}>Skip</div>
							</div>
						}
						placement="bottom"
						trigger="click"
						visible={isEditing(item)}
						onVisibleChange={handleVisibleChange(item)}
					>
						<MoreOutlined />
					</Popover>
				</div>
			),
		},
		{
			title: "Customer ID",
			dataIndex: "customerId",
			key: "customerId",
			width: 150,
			sorter: (a, b) => a.customerId - b.customerId,
		},
		{
			title: "Ext Task ID",
			dataIndex: "extTaskId",
			key: "extTaskId",
			width: 150,
			sorter: (a, b) => a.extTaskId - b.extTaskId,
		},
		{
			title: "Ext Type",
			dataIndex: "extType",
			key: "extType",
			sorter: (a, b) => a.extType.localeCompare(b.extType),
		},
		{
			title: "Period",
			dataIndex: "period",
			key: "period",
			sorter: (a, b) => a.period.localeCompare(b.period),
		},
		{
			title: "Previous Status",
			dataIndex: "prevStatus",
			key: "prevStatus",
			width: 150,
			render: (item) => (
				<Fragment>
					<TableTag
						color={JobRunStatusEnum.getColorFromKey(item)}
						background={JobRunStatusEnum.getBgColorFromKey(item)}
						name={JobRunStatusEnum.getDscpFromKey(item)}
					/>
				</Fragment>
			),
			sorter: (a, b) => a.prevStatus.localeCompare(b.prevStatus),
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 150,
			render: (item) => (
				<Fragment>
					<TableTag
						color={JobRunStatusEnum.getColorFromKey(item)}
						background={JobRunStatusEnum.getBgColorFromKey(item)}
						name={JobRunStatusEnum.getDscpFromKey(item)}
					/>
				</Fragment>
			),
			sorter: (a, b) => a.status.localeCompare(b.status),
		},
		{
			title: "Work Type",
			dataIndex: "workType",
			key: "workType",
			width: 150,
			sorter: (a, b) => a.workType.localeCompare(b.workType),
		},
		{
			title: "Result",
			dataIndex: "result",
			key: "result",
			width: 150,
			sorter: (a, b) => a.result.localeCompare(b.result),
		},
		{
			title: "Message",
			dataIndex: "message",
			key: "message",
			width: 300,
			sorter: (a, b) => a.customerId.localeCompare(b.customerId),
		},
		{
			title: "Job Work Item ID",
			dataIndex: "jobWorkItemId",
			key: "jobWorkItemId",
			width: 200,
			sorter: (a, b) => a.jobWorkItemId - b.jobWorkItemId,
		},
		{
			title: "Task Schedule Run ID",
			dataIndex: "taskScheduleRunId",
			key: "taskScheduleRunId",
			width: 200,
			sorter: (a, b) => a.taskScheduleRunId - b.taskScheduleRunId,
		},
		{
			title: "Created Date",
			dataIndex: "createdDate",
			key: "createdDate",
			render: (item) => (
				<Fragment>
					<span>{item && moment(item).format("YYYY-MM-DD h:mm a")}</span>
				</Fragment>
			),
			sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
		},
		{
			title: "Updated Date",
			dataIndex: "updatedDate",
			key: "updatedDate",
			render: (item) => (
				<Fragment>
					<span>{item && moment(item).format("YYYY-MM-DD h:mm a")}</span>
				</Fragment>
			),
			sorter: (a, b) => a.updatedDate.localeCompare(b.updatedDate),
		},
	];

	const handleVisibleChange = (record) => (isVisible) => {
		if (!isVisible) {
			setEditingKey("");
		} else {
			setEditingKey(record);
		}
	};

	const isEditing = (item) => {
		if (editingKey == item) {
			return true;
		}

		return false;
	};

	const handleCancel = (e) => {
		setIsModalVisible(false);
	};

	const onClickRunTimeDate = () => {
		setIsModalVisible(true);
		getJobWorkItems();
	};

	const getJobWorkItems = async () => {
		if (runTimeId) {
			const response = await getWorkItems(runTimeId);
			console.log("click", response);
			if (response) {
				setWorkItems(response.data.reverse());
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	};

	const onClickRetryAll = async () => {
		const response = await updateAllWorkItems(runTimeId, "Retry");
		console.log("retry", response);
		if (response) {
			getJobWorkItems();
		}
	};

	const onClickRetry = (id) => async () => {
		const response = await updateWorkItem(id, "Retry");
		console.log("retry", response);
		if (response) {
			getJobWorkItems();
			setEditingKey("");
		}
	};

	const onClickSkip = (id) => async () => {
		const response = await updateWorkItem(id, "Skip");
		console.log("Skip", response);
		if (response) {
			getJobWorkItems();
			setEditingKey("");
		}
	};

	return (
		<Fragment>
			<div>
				<span className="modal-span" onClick={onClickRunTimeDate}>
					{runTimeDate}
				</span>
				<Modal
					title="Job Work Items"
					visible={isModalVisible}
					width={2000}
					footer={null}
					onCancel={handleCancel}
					className="modal-work"
				>
					{workItems.length > 0 && (
						<div className="retry-btn">
							<Button type="primary" onClick={onClickRetryAll}>
								Retry All
							</Button>
						</div>
					)}

					<DynamicTable
						data={workItems}
						columnHeader={header}
						loading={isLoading}
						scroll={{ x: "max-content", y: 500 }}
						pagination={{
							pageSize: 50,
							total: workItems.length,
							showSizeChanger: false,
							defaultCurrent: 1,
						}}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};

export default WorkItemModal;
